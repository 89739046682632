.page-header__bg{
    background-image: url("../../../../public/images/backgrounds/mission-bg.png");
}
.cta-one__bg-img{
    background-image: url("../../../../public/images/backgrounds/home-contact-img-2.png");
}
.ft-section-title-3{
    visibility: visible; animation-duration: 1500ms; animation-delay: 0ms; animation-name: fadeInUp;
}
.ft-about-sub-text{
    visibility: visible; animation-duration: 1500ms; animation-delay: 200ms; animation-name: fadeInUp;
}
.ft-about--{
    visibility: visible; animation-duration: 1500ms; animation-delay: 0ms; animation-name: fadeInLeft;
}

.counter-one{
    padding: 120px 0 0;
}