@tailwind base;
@tailwind components;
@tailwind utilities;


html,body {
    font: 400 13px Roboto, "Helvetica Neue", sans-serif;
    // overflow: hidden;
}

.rdrDefinedRangesWrapper {
  display: none;
}

.curl-body{
  // width: 200vh;
  overflow-x: auto;
  white-space: pre-wrap;
  word-break: break-all;
}


:where(.css-dev-only-do-not-override-3mqfnx).ant-menu-inline .ant-menu-item, 
:where(.css-dev-only-do-not-override-3mqfnx).ant-menu-vertical .ant-menu-item, 
:where(.css-dev-only-do-not-override-3mqfnx).ant-menu-inline .ant-menu-submenu-title, 
:where(.css-dev-only-do-not-override-3mqfnx).ant-menu-vertical .ant-menu-submenu-title {
  border-radius: 0;
}

.header-left-border {
  border-left: 1px solid #808080;
}
.detail-left-border {
  border-left: 1px solid #e2e4e5;
}

//Form Input box- Error div CSS
.error-box {
  height: 6px;
}

//Breadcrumb CSS
.breadcrumb-link {

  transition: transform .3s;
  &:hover{
    transform: scale(1.05);
    box-shadow: 0 40px 50px 0px rgba(0, 0, 0, 0);
  }
}

// no box shadow in Paper container and Accordion
.css-1ps6pg7-MuiPaper-root {
  box-shadow: none;
}

.css-1wc0u5o-MuiPaper-root-MuiAccordion-root:first-of-type{
  box-shadow: none;
}

//No record found image
.noRecordFound{
  height: 30vh !important;
  width: 100%;
}

//Show single calendar for Date Range Picker
.custom-datepicker-dropdown {
  .ant-picker-panel-container {
    .ant-picker-panels {
      .ant-picker-panel:not(:first-child) {
        display: none;
      }
      :first-child {
        button.ant-picker-header-next-btn {
          visibility: visible !important;
        }
      }
    }
  }
}

// Swal Alert CSS
.swal2-container {
  .swal2-title{
    font-size: 16px;
  }

  .swal2-html-container{
    font-size: 1.1rem;
    font-weight: 600 !important;
    margin-top: 0.8rem !important;
  }
}

// File Upload display within input box
.logoImage {
  display: inline;
}


// Set column width for Action column
.css-nsjprl-MuiTableCell-root,
 .css-tyjwsk-MuiTableCell-root 
  {
 min-width: max(calc(100 * 1px), 40px) !important;
 width: calc(100 * 1px) !important;
 text-align: center !important;
}
// .css-tyjwsk-MuiTableCell-root {
//   width:inherit !important
// }

//put switch button on the middle of action field
.css-julti5-MuiSwitch-root{
  margin-top: -19px !important;
}
.border-input{
  border: 1px solid rgba(0, 0, 0, 0.23);
}
.MuiBox-root.css-1cz1a29{
  width: 100%;
}
.file-input input{
  position: relative;
}
.file-input input::before{
  content: '';
  position: absolute;
  width: 80px;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgb(152 147 140 / 21%);
  z-index: 0;
  // box-shadow: 0 0 10px 0 #ccc;
  border: 1px solid #e1d7e1;
}
.input-form-data{
border: 1px solid #e0e0e0 !important;
}
.input-form-data .MuiInputBase-colorPrimary{
background-color: #f9f9f9 !important;
}
.input-form-data fieldset:focus-visible{
outline: none !important;
}
.input-form-data fieldset{
  border: none !important;
}

