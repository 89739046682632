/* Vertical one */
 
.vertical {
    padding: 95px 0px;
    overflow: hidden;
    background-color: #f5f8fd;
  }
   
  .vertical .vertical-title p {
    padding-bottom: 15px;
  }
   
  .vertical-title .vertical-tag {
    font-size: 18px;
    font-weight: 700;
    color: #000d51;
  }
   
  .vertical-title .vertical-tag:after {
    top: 15px;
    height: 2px;
    width: 35px;
    content: "";
    right: -40px;
    position: absolute;
    background-color: #000d51;
  }
   
  .vertical-title .vertical-tag {
    font-size: 18px;
    font-weight: 700;
    color: #000d51;
  }
   
  .vertical-title h2 span {
    color: #000d51;
  }
   
  .vertical-title h2 {
    font-size: 48px;
    font-weight: 700;
    color: #fb8200;
    padding: 20px 0px;
  }
   
  .vertical .vertical-title p {
    padding-bottom: 15px;
  }
   
  .vertical-list {
    padding: 20px 0px;
  }
   
  .ul-li-block ul {
    margin: 0;
    padding: 0;
  }
   
  .ul-li-block ul li {
    display: block;
    list-style: none;
  }
   
  .vertical-list li {
    padding-left: 55px;
    margin-bottom: 18px;
    position: relative;
  }
   
  .vertical-list li i {
    top: 0;
    left: 0;
    font-size: 25px;
    /* content: ""; */
    font-weight: 900;
    color: #f39f2f;
    position: absolute;
    /* font-family: "Font Awesome 5 Pro"; */
  }
   
  .vertical-img-area {
    background-color: #254671;
    width: 684px;
    height: 558px;
  }
   
  .text-color-content {
    color: #222;
    font-weight: 500;
  }
   
  .ft-about-shape1 {
    right: 0;
    top: -1px;
    position: absolute;
    width: 170px;
  }
   
  /* .pr-sx-about-exp {
    top: 55px;
    right: -120px;
    width: 260px;
    height: 105px;
    padding-left: 15px;
    border-radius: 8px;
    position: absolute;
    background-color: #fff;
    -webkit-box-shadow: 13.435px 13.435px 30px 0px rgba(55, 54, 54, 0.2);
    box-shadow: 13.435px 13.435px 30px 0px rgba(55, 54, 54, 0.2);
  } */
   
  /* Vertical one */
   
  /* vertical section */
   
  .ft3-services-section-four {
    position: relative;
    overflow: visible;
    z-index: 10;
    padding: 100px 0px 200px;
  }
   
  .ft3-services-section-four .ft3-service-block-three {
    padding: 0px 0px;
    float: left;
  }
   
  .ft3-service-block-three {
    position: relative;
    margin-bottom: 40px;
  }
   
  .ft3-service-block-three .inner-box {
    position: relative;
    padding: 275px 50px;
  }
   
  .ft3-service-block-three .inner-box h5 {
    position: relative;
    padding-bottom: 15px;
  }
   
  .ft3-service-block-three .inner-box h5:before {
    position: absolute;
    content: "";
    left: 0px;
    bottom: 0px;
    height: 3px;
    width: 40px;
    /* background-color: #dd2602; */
  }
   
  .ft3-service-block-three .inner-box .text {
    position: relative;
    color: #454545;
    font-size: 16px;
    line-height: 1.6em;
    margin-top: 30px;
  }
   
  .ft3-service-block-three .inner-box .overlay-box .shape-layer {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    height: 180px;
    -webkit-clip-path: polygon(0 0, 100% 0, 75% 100%, 25% 100%);
    clip-path: polygon(0 0, 100% 0, 75% 100%, 25% 100%);
  }
   
  .ft3-service-block-three .inner-box .overlay-box {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    text-align: center;
    -webkit-transition: all 900ms ease;
    transition: all 900ms ease;
    -webkit-transform: perspective(400px) rotateX(-90deg);
    transform: perspective(400px) rotateX(-90deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
  }
   
  .ft3-service-block-three .overlay-box .overlay-image-layer {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    height: 100%;
  }
   
  .bg-global {
    background-color: #948ebe40;
  }
   
  .bg-global-shap {
    background-color: #948ebe;
  }
   
  .bg-personal {
    background-color: #b4b52940;
  }
   
  .bg-personal-shap {
    background-color: #b4b529;
  }
   
  .bg-express {
    background-color: #01d0b440;
  }
   
  .bg-express-shap {
    background-color: #01d0b4;
  }
   
  .bg-dangerous {
    background-color: #dd293040;
  }
   
  .bg-dangerous-shap {
    background-color: #dd2930;
  }
   
  .bg-project {
    background-color: #03a63a40;
  }
   
  .bg-project-shap {
    background-color: #03a63a;
  }
   
  .bg-temp {
    background-color: #f052a640;
  }
   
  .bg-temp-shap {
    background-color: #f052a6;
  }
   
  .ft3-service-block-three.active .inner-box .overlay-box,
  .ft3-service-block-three .inner-box:hover .overlay-box {
    -webkit-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
  }
   
  .ft3-service-block-three .overlay-inner {
    display: flex;
    padding: 10px 30px;
  }
   
  .ft3-service-block-three .overlay-inner .content {
    position: relative;
    display: table-cell;
    vertical-align: middle;
  }
   
  .icon-box {
    margin: auto 121px;
    padding-top: 20px;
  }
   
  .ft3-service-block-three .overlay-inner h5 {
    position: relative;
    padding-bottom: 15px;
    margin-top: 62px;
    font-size: 20px;
    color: #222222;
  }
   
  .ft3-service-block-three .overlay-inner h5:before {
    position: absolute;
    content: "";
    left: 50%;
    bottom: 0px;
    height: 3px;
    width: 34px;
    margin-left: -17px;
    background-color: #969595;
  }
   
  .ft3-service-block-three .overlay-inner .lower-text {
    position: relative;
    font-size: 16.5px;
    line-height: 1.6em;
    margin-top: 20px;
    text-align: justify;
    color: #222;
  }
   
  /* vertical section */