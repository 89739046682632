.contact {
    padding: 70px 0 0;
}

.contact .heading h2 {
    font-size: 30px;
    font-weight: 700;
    margin: 0;
    padding: 0;
}

.contact .heading h2 span {
    color: #ff9100;
}

.contact .heading p {
    font-size: 15px;
    font-weight: 400;
    line-height: 1.7;
    color: #999999;
    margin: 20px 0 60px;
    padding: 0;
}

.contact .form-control {
    padding: 14px;
    height: 60px;
    font-size: 14px;
    margin-bottom: 30px;
    background: #f9f9f9;
    border: 0;
    border-radius: 0px;
    border: 1px solid #e3e4e9;
}

.contact button.btn {
    padding: 10px 30px;
    border-radius: 10px;
    font-size: 15px;
    background: #f39f2f;
    color: #ffffff;
    margin-top: 34px;
}

.contact-btn {
    margin-top: 34px;
}

.faq-one__btn-box {
    text-align: center;
}

.contact .title h3 {
    font-size: 18px;
    font-weight: 600;
}

.contact .title p {
    font-size: 14px;
    font-weight: 400;
    color: #999;
    line-height: 1.6;
    margin: 0 0 40px;
}

.contact .content .info {
    margin-top: 30px;
}

.contact .content .info i {
    font-size: 26px;
    line-height: 44px;
    padding: 0;
    margin: 0;
    color: #02434b;
    text-align: center;
}

.contact .content .info h4 {
    font-size: 21px;
    color: #fff;
    font-weight: 800;
}

.contact .content .info h4 span {
    font-size: 18px;
    /* line-height: 1.4; */
    color: #fff;
    font-weight: 600;
}

.contact-page {
    background-image: url("../../../../public/images/project/solution.png");
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.contact-page::before {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: rgb(36 71 112 / 86%);
}

.contact-page .title h3,
.contact-page .title p,
.contact-page .content {
    position: relative;
}

.contact-page .title {
    padding: 30px 20px 10px;
}

.contact-page .title h3 {
    font-size: 27px;
    font-weight: 700;
    color: #f39f2f;
}

.contact-page .title p {
    font-size: 16px;
    color: #fff;
    line-height: 22px;
}

.contact-page .content {
    padding: 10px 20px 30px;
}

.contact-page .content .info .icon {
    height: 45px;
    width: 45px;
    border-radius: 50%;
    background-color: #f39f2f;
    margin-right: 20px;
    text-align: center;
}

.contact-page .content .info {
    display: flex;
    align-items: center;
}

.contact-page .content .info .icon i {
    color: #fff;
}

.add-section {
    background-color: #fff;
    box-shadow: 0 0 4px 0 #ccc;
    padding: 20px;
    display: table;
    height: 100%;
    width: 100%;
}

.add-section .add-- {
    display: table-cell;
    vertical-align: middle;
}

.add-section .add-- .icon {
    height: 45px;
    width: 45px;
    background-color: #ff9100;
    border-radius: 50%;
    margin-bottom: 7px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding: 13px;
}

.add-section .add-- .icon i {
    color: #fff;
    font-size: 25px;
    line-height: 20px;
    text-align: center;
}

.add-section .add-- h3 {
    text-align: center;
    font-size: 16px;
    line-height: 21px;
    padding-top: 10px;
    font-weight: 600;
}

.contact-add-section {
    margin: 40px 0;
}

.contact-detail-sec {
    margin-bottom: 50px;
}