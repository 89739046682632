/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Utility
# Cards
# Common
# Form
# Navigations
# Animations
# Mobile Nav
# Search Popup
# Page Header
# Google Map
# Client Carousel
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Common
--------------------------------------------------------------*/
:root {
  --onpoint-font: "Jost", sans-serif;
  --onpoint-font-two: "Rajdhani", sans-serif;
  --onpoint-gray: #787878;
  --onpoint-gray-rgb: 120, 120, 120;
  --onpoint-white: #ffffff;
  --onpoint-white-rgb: 255, 255, 255;
  --onpoint-base: #f39f2f;
  --onpoint-base-rgb: 193, 20, 37;
  --onpoint-black: #0F1B24;
  --onpoint-black-rgb: 15, 27, 36;
  --onpoint-primary: #FFC000;
  --onpoint-primary-rgb: 255, 192, 0;
  --onpoint-extra: #F5F5F5;
  --onpoint-extra-rgb: 245, 245, 245;
  --onpoint-bdr-color: #CCCCCC;
  --onpoint-bdr-rgb: 204, 204, 204;
}

.row {
  --bs-gutter-x: 30px;
}

.gutter-y-30 {
  --bs-gutter-y: 30px;
}

body {
  font-family: var(--onpoint-font);
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  color: var(--onpoint-gray);
  overflow-x: hidden;
}

body.locked {
  overflow: hidden;
}

a {
  color: var(--onpoint-base);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

a,
a:hover,
a:focus,
a:visited {
  text-decoration: none;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: 1;
}

:-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}

::-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}

::placeholder {
  color: inherit;
  opacity: 1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--onpoint-font-two);
  color: var(--onpoint-black);
  margin: 0;
}

p {
  margin: 0;
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 0;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: 1;
}

:-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}

::-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}

::placeholder {
  color: inherit;
  opacity: 1;
}

.page-wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  min-width: 300px;
  margin-top: 100px;
}

.container {
  padding-left: 15px;
  padding-right: 15px;
}

.list-unstyled {
  padding-left: 0;
}

@media (min-width: 1320px) {
  .container {
    max-width: 1320px;
  }
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: 1;
}

:-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}

::-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}

::placeholder {
  color: inherit;
  opacity: 1;
}

.section-separator {
  border-color: var(--onpoint-border);
  border-width: 1px;
  margin-top: 0;
  margin-bottom: 0;
}

#particles-js {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-position: 50% 50%;
  opacity: 0.4;
  z-index: -1;
}

.section-title {
  position: relative;
  display: block;
  margin-bottom: 60px;
  margin-top: -8px;
}

.section-title__tagline-box {
  position: relative;
  display: inline-block;
}

.ft-contact-content .section-title__tagline-box {
  margin-left: 75px;
}

.section-title__tagline {
  position: relative;
  display: inline-block;
  font-size: 18px;
  line-height: 18px;
  color: var(--onpoint-base);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  font-family: var(--onpoint-font-two);
}

.section-title__title {
  color: var(--onpoint-black);
  font-size: 48px;
  line-height: 48px;
  font-weight: 700;
  text-transform: capitalize;
  margin: 15px 0;
}

.thm-btn {
  position: relative;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  -webkit-appearance: none;
  appearance: none;
  outline: none !important;
  font-weight: 500;
  font-size: 13px;
  color: var(--onpoint-white);
  text-transform: capitalize;
  padding: 5px 24px 5px;
  padding-right: 4px;
  letter-spacing: 1.6px;
  border-radius: 30px;
  border: 1px solid var(--onpoint-bdr-color);
  font-family: var(--onpoint-font-two);
  background-color: var(--onpoint-base);
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
  overflow: hidden;
  gap: 9px;
  z-index: 1;
}

.contact-btn button {
  background-color: #254671 !important;
  color: #ffffff !important;
}

.thm-btn::after {
  content: "";
  background-color: var(--onpoint-black);
  position: absolute;
  transition: all .3s ease-in;
  z-index: -1;
  height: 100%;
  left: -35%;
  top: 0;
  transform: skew(30deg);
  transition-duration: .6s;
  transform-origin: top left;
  width: 0
}

.thm-btn:hover::after {
  height: 100%;
  width: 135%
}

.thm-btn:hover {
  color: var(--onpoint-white);
}

.thm-btn>span {
  position: relative;
  width: 48px;
  height: 48px;
  background-color: rgba(var(--onpoint-white-rgb), .20);
  border-radius: 50%;
  transition: 0.2s;
}

.thm-btn>span::after {
  position: absolute;
  top: 0;
  left: 2px;
  right: 0;
  bottom: 0;
  padding-top: inherit;
  padding-bottom: inherit;
  content: "\e911";
  font-family: 'icomoon' !important;
  font-size: 15px;
  text-align: center;
  transition: 0.2s;
  transform-origin: 50% 60%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.thm-btn:hover>span::after {
  -webkit-animation: bounceright 0.3s alternate ease infinite;
  animation: bounceright 0.3s alternate ease infinite;
}

.bootstrap-select .btn-light:not(:disabled):not(.disabled).active,
.bootstrap-select .btn-light:not(:disabled):not(.disabled):active,
.bootstrap-select .show>.btn-light.dropdown-toggle {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.bootstrap-select>.dropdown-toggle {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.bootstrap-select .dropdown-toggle:focus,
.bootstrap-select>select.mobile-device:focus+.dropdown-toggle {
  outline: none !important;
}

.bootstrap-select .dropdown-menu {
  border: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  z-index: 991;
  border-radius: 0;
}

.bootstrap-select .dropdown-menu>li+li>a {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.bootstrap-select .dropdown-menu>li.selected>a {
  background: var(--onpoint-base);
  color: var(--onpoint-white);
}

.bootstrap-select .dropdown-menu>li>a {
  font-size: 16px;
  font-weight: 500;
  padding: 4px 12px;
  color: #ffffff;
  text-transform: uppercase;
  background: var(--onpoint-black);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.bootstrap-select .dropdown-menu>li>a:hover {
  background: var(--onpoint-base);
  color: var(--onpoint-white);
  cursor: pointer;
}

/* .preloader {
    position: fixed;
    background-color: #fff;
    background-position: center center;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  } */

/* .preloader__image {
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: flipInY;
    animation-name: flipInY;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    background-image: url(../images/loader.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 60px auto;
    width: 100%;
    height: 100%;
  } */

.preloader {
  /* position: fixed; */
  left: 0;
  top: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
  overflow: visible;
  background: #ffff url(../../public/images/loader-1.svg) no-repeat center center;
}

/* scroll to top */
/* .scroll-to-top {
  display: inline-block;
  width: 40px;
  height: 40px;
  background: var(--onpoint-black);
  position: fixed;
  bottom: 83px;
  right: 28px;
  z-index: 99;
  text-align: center;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  display: none;
  border-radius: 50%;
}

.scroll-to-target i {
  color: var(--onpoint-white);
  font-size: 18px;
  line-height: 40px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.scroll-to-target:hover {
  background-color: var(--onpoint-base);
}

.scroll-to-top:hover i {
  color: var(--onpoint-white);
} */



/** xs sidebar **/
.xs-sidebar-group .xs-overlay {
  left: 0%;
  top: 0;
  position: fixed;
  height: 100%;
  opacity: 0;
  width: 100%;
  visibility: hidden;
  -webkit-transition: all .4s ease-in .8s;
  -o-transition: all .4s ease-in .8s;
  transition: all .4s ease-in .8s;
  /* *** cursor: url(../../public/images/icon/cross-out.html),pointer; */
  z-index: 9999;
}

.xs-sidebar-group.isActive .xs-overlay {
  opacity: .8;
  visibility: visible;
  -webkit-transition: all .8s ease-out 0s;
  -o-transition: all .8s ease-out 0s;
  transition: all .8s ease-out 0s;
  right: 100%;
}

.xs-sidebar-group .widget-heading {
  position: absolute;
  top: 0;
  right: 0;
  padding: 25px;
}

.xs-sidebar-group .widget-heading a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border: 2px solid #fff;
  border-radius: 50%;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.xs-sidebar-group .widget-heading a:hover {
  color: var(--onpoint-base);
  border-color: var(--onpoint-base);
}

.xs-sidebar-widget {
  position: fixed;
  left: -100%;
  top: 0;
  bottom: 0;
  width: 100%;
  max-width: 360px;
  z-index: 999999;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-transition: all 0.5s cubic-bezier(0.9, 0.03, 0, 0.96) 0.6s;
  -o-transition: all 0.5s cubic-bezier(0.9, 0.03, 0, 0.96) 0.6s;
  transition: all 0.5s cubic-bezier(0.9, 0.03, 0, 0.96) 0.6s;
  visibility: hidden;
  opacity: 0;
}

.xs-sidebar-group.isActive .xs-sidebar-widget {
  opacity: 1;
  visibility: visible;
  left: 0;
  background-color: var(--onpoint-black);
  -webkit-transition: all 0.7s cubic-bezier(0.9, 0.03, 0, 0.96) 0.4s;
  -o-transition: all 0.7s cubic-bezier(0.9, 0.03, 0, 0.96) 0.4s;
  transition: all 0.7s cubic-bezier(0.9, 0.03, 0, 0.96) 0.4s;
}

.sidebar-textwidget {
  padding: 70px 30px;
}

.sidebar-widget-container {
  position: relative;
  top: 150px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all .3s ease-in .3s;
  -o-transition: all .3s ease-in .3s;
  transition: all .3s ease-in .3s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.xs-sidebar-group.isActive .sidebar-widget-container {
  top: 0px;
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 1s ease-out 1.2s;
  -o-transition: all 1s ease-out 1.2s;
  transition: all 1s ease-out 1.2s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.xs-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: .8;
  z-index: 0;
}

.xs-bg-black {
  background-color: #000000;
}

.xs-sidebar-group .content-inner .logo {
  position: relative;
  max-width: 172px;
  width: 100%;
  margin-bottom: 30px;
}

.xs-sidebar-group .content-inner h4 {
  position: relative;
  display: block;
  font-size: 20px;
  line-height: 30px;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.xs-sidebar-group .content-inner .content-box {
  margin-bottom: 30px;
  padding-right: 15px;
}

.xs-sidebar-group .content-inner .content-box p {
  color: #ffffff;
}

.xs-sidebar-group .content-inner .form-inner .form-group {
  position: relative;
  margin-bottom: 20px;
}

.xs-sidebar-group .content-inner .form-inner .form-group:last-child {
  margin-bottom: 0px;
}

.xs-sidebar-group .content-inner .form-inner .form-group input[type='text'],
.xs-sidebar-group .content-inner .form-inner .form-group input[type='email'],
.xs-sidebar-group .content-inner .form-inner .form-group textarea {
  position: relative;
  display: block;
  width: 100%;
  height: 50px;
  font-size: 15px;
  padding: 10px 20px;
  color: #848484;
  border: none;
  outline: none;
  transition: all 500ms ease;
}

.xs-sidebar-group .content-inner .form-inner .form-group .form-inner__btn {
  border: none;
  outline: none;
  background-color: var(--onpoint-white);
  color: var(--onpoint-black);
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  padding: 13px 45px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.xs-sidebar-group .content-inner .form-inner .form-group .form-inner__btn:hover {
  color: var(--onpoint-white);
}

.xs-sidebar-group .content-inner .form-inner .form-group .form-inner__btn:after {
  background-color: var(--onpoint-base);
}

.xs-sidebar-group .content-inner .form-inner .form-group textarea {
  resize: none;
  height: 120px;
}

.xs-sidebar-group .content-inner .form-inner .form-group input:focus,
.xs-sidebar-group .content-inner .form-inner .form-group textarea:focus {
  border-color: #00224f;
}


/*--------------------------------------------------------------
  # Navigations One
  --------------------------------------------------------------*/
.main-header {
  background: #fff;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  display: block;
  width: 100%;
  transition: all 500ms ease;
  z-index: 999;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}



.main-menu {
  position: relative;
  display: block;
  z-index: 1;
}

.main-menu__wrapper {
  position: relative;
  display: block;
  z-index: 1;
}

.main-menu__wrapper-inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  z-index: 1;
}

.main-menu__left {
  position: relative;
  display: flex;
  align-items: center;
  gap: 70px;
  flex-wrap: wrap;
  width: 60%;
  justify-content: space-between;
}

.main-menu__logo {
  position: relative;
  display: block;
  padding: 20px 0;
  z-index: 1;
}

.main-menu__logo a {
  position: relative;
  display: block;
}

.main-menu__main-menu-box {
  position: relative;
  display: block;
}

.main-menu__right {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.main-menu__search-nav-sidebar-btn-box {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.main-menu__search-box {
  position: relative;
  display: block;
  padding: 20px 0;
}

.main-menu__search-box:before {
  content: "";
  position: absolute;
  top: 10px;
  bottom: 10px;
  right: -25px;
  width: 1px;
  background-color: rgba(var(--onpoint-black-rgb), .40);
}

.main-menu__search {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 20px;
  color: var(--onpoint-black);
}

.main-menu__search:hover {
  color: var(--onpoint-base);
}

.main-menu__nav-sidebar-icon {
  position: relative;
  display: block;
  /* margin-left: 50px; */
  margin-right: 35px;
  padding: 15px 0;
}

.main-menu__nav-sidebar-icon .navSidebar-button {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  height: 30px;
  width: 30px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.icon-dots-menu-one {
  position: relative;
  display: block;
  width: 20px;
  height: 2px;
  background-color: var(--onpoint-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-menu__nav-sidebar-icon .navSidebar-button:hover .icon-dots-menu-one {
  width: 30px;
}

/* .icon-dots-menu-two {
    position: relative;
    display: block;
    width: 30px;
    height: 2px;
    background-color: var(--onpoint-black);
    margin-top: 8px;
    margin-bottom: 8px;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
  } */

/* .icon-dots-menu-three {
    position: relative;
    display: block;
    width: 25px;
    height: 2px;
    background-color: var(--onpoint-black);
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
  } */

/* .main-menu__nav-sidebar-icon .navSidebar-button:hover .icon-dots-menu-three {
    width: 30px;
  } */

.main-menu__btn-box {
  position: relative;
  display: block;
}

.main-menu__btn {
  border: none;
  padding: 6px 24px 6px;
  padding-right: 4px;
}

/* .stricky-header.main-menu {
    background-color: var(--onpoint-white);
  } */

.main-menu .main-menu__list,
.main-menu .main-menu__list>li>ul,
.main-menu .main-menu__list>li>ul>li>ul,
.stricky-header .main-menu__list,
.stricky-header .main-menu__list>li>ul,
.stricky-header .main-menu__list>li>ul>li>ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: none;
}



@media (min-width: 1200px) {

  .main-menu .main-menu__list,
  .main-menu .main-menu__list>li>ul,
  .main-menu .main-menu__list>li>ul>li>ul,
  .stricky-header .main-menu__list,
  .stricky-header .main-menu__list>li>ul,
  .stricky-header .main-menu__list>li>ul>li>ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.main-menu .main-menu__list>li,
.stricky-header .main-menu__list>li {
  padding-top: 37px;
  padding-bottom: 37px;
  position: relative;
}

.main-menu .main-menu__list>li+li,
.stricky-header .main-menu__list>li+li {
  margin-left: 30px;
}

.main-menu .main-menu__list>li>a,
.stricky-header .main-menu__list>li>a {
  font-size: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--onpoint-black);
  font-weight: 500;
  font-family: var(--onpoint-font-two);
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-menu .main-menu__list>li.dropdown>a {
  padding-right: 16px;
}

.main-menu .main-menu__list>li.dropdown>a:after {
  position: absolute;
  top: 50%;
  right: 0;
  font-family: "Font Awesome 5 Free";
  content: "\f107";
  font-size: 14px;
  color: var(--onpoint-black);
  transform: translateY(-50%);
  font-weight: 700;
  z-index: 1;
}

.main-menu .main-menu__list>li.current>a::after,
.main-menu .main-menu__list>li:hover>a::after,
.stricky-header .main-menu__list>li.current>a::after,
.stricky-header .main-menu__list>li:hover>a::after {
  color: var(--onpoint-base);
}

.main-menu .main-menu__list>li.current>a,
.main-menu .main-menu__list>li:hover>a,
.stricky-header .main-menu__list>li.current>a,
.stricky-header .main-menu__list>li:hover>a {
  color: var(--onpoint-base);
}

.main-menu .main-menu__list>li>ul,
.main-menu .main-menu__list>li>ul>li>ul,
.stricky-header .main-menu__list>li>ul,
.stricky-header .main-menu__list>li>ul>li>ul {
  position: absolute;
  top: 100%;
  left: -62px;
  min-width: 250px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  opacity: 0;
  visibility: hidden;
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-transform: scaleY(0) translateZ(100px);
  transform: scaleY(0) translateZ(100px);
  -webkit-transition: opacity 500ms ease, visibility 500ms ease, -webkit-transform 700ms ease;
  transition: opacity 500ms ease, visibility 500ms ease, -webkit-transform 700ms ease;
  transition: opacity 500ms ease, visibility 500ms ease, transform 700ms ease;
  transition: opacity 500ms ease, visibility 500ms ease, transform 700ms ease, -webkit-transform 700ms ease;
  z-index: 99;
  background-color: #fff;
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
}

.main-menu .main-menu__list>li>ul>li>ul>li>ul,
.stricky-header .main-menu__list>li>ul>li>ul>li>ul {
  display: none;
}

.main-menu .main-menu__list>li:hover>ul,
.main-menu .main-menu__list>li>ul>li:hover>ul,
.stricky-header .main-menu__list>li:hover>ul,
.stricky-header .main-menu__list>li>ul>li:hover>ul {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scaleY(1) translateZ(0px);
  transform: scaleY(1) translateZ(0px);
}

.main-menu .main-menu__list>li>ul>li,
.main-menu .main-menu__list>li>ul>li>ul>li,
.stricky-header .main-menu__list>li>ul>li,
.stricky-header .main-menu__list>li>ul>li>ul>li {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  width: 100%;
  position: relative;
  border-bottom: 1px solid rgba(var(--onpoint-bdr-rgb), .50);
}

.main-menu .main-menu__list>li>ul>li:last-child,
.main-menu .main-menu__list>li>ul>li>ul>li:last-child,
.stricky-header .main-menu__list>li>ul>li:last-child,
.stricky-header .main-menu__list>li>ul>li>ul>li:last-child {
  border-bottom: 0;
}

.main-menu .main-menu__list>li>ul>li+li,
.main-menu .main-menu__list>li>ul>li>ul>li+li,
.stricky-header .main-menu__list>li>ul>li+li,
.stricky-header .main-menu__list>li>ul>li>ul>li+li {
  border-top: none;
  margin-top: 0px;
}

.main-menu .main-menu__list>li>ul>li>a,
.main-menu .main-menu__list>li>ul>li>ul>li>a,
.stricky-header .main-menu__list>li>ul>li>a,
.stricky-header .main-menu__list>li>ul>li>ul>li>a {
  position: relative;
  font-size: 13px;
  line-height: 30px;
  color: var(--onpoint-black);
  /* font-weight: 500; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 7px 20px 7px;
  -webkit-transition: 500ms;
  transition: 500ms;
}

.main-menu .main-menu__list>li>ul>li:hover>a,
.main-menu .main-menu__list>li>ul>li>ul>li:hover>a,
.stricky-header .main-menu__list>li>ul>li:hover>a,
.stricky-header .main-menu__list>li>ul>li>ul>li:hover>a {
  background-color: var(--onpoint-base);
  color: var(--onpoint-white);
}

.main-menu .main-menu__list>li>ul>li>ul,
.stricky-header .main-menu__list>li>ul>li>ul {
  top: 0;
  left: 100%;
}

.main-menu .main-menu__list li ul li>ul.right-align,
.stricky-header .main-menu__list li ul li>ul.right-align {
  top: 0;
  left: auto;
  right: 100%;
}


.stricky-header {
  position: fixed;
  z-index: 991;
  top: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  visibility: hidden;
  -webkit-transform: translateY(-120%);
  transform: translateY(-120%);
  -webkit-transition: visibility 500ms ease, -webkit-transform 500ms ease;
  transition: visibility 500ms ease, -webkit-transform 500ms ease;
  transition: transform 500ms ease, visibility 500ms ease;
  transition: transform 500ms ease, visibility 500ms ease, -webkit-transform 500ms ease;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
}

@media (max-width: 1199px) {
  .stricky-header {
    display: none !important;
  }
}

.stricky-header.stricky-fixed {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  visibility: visible;
}

.stricky-header .main-menu__inner {
  -webkit-box-shadow: none;
  box-shadow: none;
  padding-right: 0;
  max-width: 1170px;
  width: 100%;
  margin: 0 auto;
}

/* .mobile-nav__buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-left: auto;
    margin-right: 10px;
  } */

/* @media (min-width: 1200px) {
    .mobile-nav__buttons {
      display: none;
    }
  } */

/* .mobile-nav__buttons a {
    font-size: 20px;
    color: var(--onpoint-base);
    cursor: pointer;
  } */

/* .mobile-nav__buttons a+a {
    margin-left: 10px;
  } */

/* .mobile-nav__buttons a:hover {
    color: var(--onpoint-base);
  } */

/* .main-menu .mobile-nav__toggler {
    position: relative;
    display: inline-block;
    font-size: 20px;
    color: var(--onpoint-base);
    cursor: pointer;
    -webkit-transition: 500ms;
    transition: 500ms;
  } */

/* .main-menu .mobile-nav__toggler:hover {
    color: var(--onpoint-black);
  } */

@media (min-width: 1200px) {
  .main-menu .mobile-nav__toggler {
    display: none;
  }
}

/*--------------------------------------------------------------
  # Mobile Nav
  --------------------------------------------------------------*/
.mobile-nav__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transform-origin: left center;
  transform-origin: left center;
  -webkit-transition: visibility 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
  transition: visibility 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
  transition: transform 500ms ease 500ms, visibility 500ms ease 500ms;
  transition: transform 500ms ease 500ms, visibility 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
  visibility: hidden;
}

.mobile-nav__wrapper .container {
  padding-left: 0;
  padding-right: 0;
}

.mobile-nav__wrapper.expanded {
  opacity: 1;
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
  visibility: visible;
  -webkit-transition: visibility 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
  transition: visibility 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
  transition: transform 500ms ease 0ms, visibility 500ms ease 0ms;
  transition: transform 500ms ease 0ms, visibility 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
}

.mobile-nav__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  opacity: 0.5;
  cursor: pointer;
}

.mobile-nav__content {
  width: 300px;
  background-color: var(--onpoint-black);
  z-index: 10;
  position: relative;
  height: 100%;
  overflow-y: auto;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: opacity 500ms ease 0ms, visibility 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
  transition: opacity 500ms ease 0ms, visibility 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
  transition: opacity 500ms ease 0ms, visibility 500ms ease 0ms, transform 500ms ease 0ms;
  transition: opacity 500ms ease 0ms, visibility 500ms ease 0ms, transform 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
}

.mobile-nav__wrapper.expanded .mobile-nav__content {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: opacity 500ms ease 500ms, visibility 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
  transition: opacity 500ms ease 500ms, visibility 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
  transition: opacity 500ms ease 500ms, visibility 500ms ease 500ms, transform 500ms ease 500ms;
  transition: opacity 500ms ease 500ms, visibility 500ms ease 500ms, transform 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
}

.mobile-nav__content .logo-box {
  margin-bottom: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.mobile-nav__close {
  position: absolute;
  top: 20px;
  right: 15px;
  font-size: 18px;
  color: var(--onpoint-white);
  cursor: pointer;
}

.mobile-nav__content .main-menu__list,
.mobile-nav__content .main-menu__list>li>ul,
.mobile-nav__content .main-menu__list>li>ul>li>ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.mobile-nav__content .main-menu__list>li>ul,
.mobile-nav__content .main-menu__list>li>ul>li>ul {
  display: none;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  margin-left: 0.5em;
}

.mobile-nav__content .main-menu__list>li:not(:last-child),
.mobile-nav__content .main-menu__list>li>ul>li:not(:last-child),
.mobile-nav__content .main-menu__list>li>ul>li>ul>li:not(:last-child) {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.mobile-nav__content .main-menu__list>li>a>.main-menu-border {
  display: none !important;
}

.mobile-nav__content .main-menu__list>li>a,
.mobile-nav__content .main-menu__list>li>ul>li>a,
.mobile-nav__content .main-menu__list>li>ul>li>ul>li>a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  line-height: 30px;
  color: #ffffff;
  font-size: 14px;
  font-family: var(--onpoint-font);
  font-weight: 500;
  height: 46px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: 500ms;
  transition: 500ms;
}

.mobile-nav__content .main-menu__list>li>a.expanded .mobile-nav__content .main-menu__list>li>ul>li>a.expanded .mobile-nav__content .main-menu__list>li>ul>li>ul>li>a.expanded {
  color: var(--onpoint-base);
}

.mobile-nav__content .main-menu__list li a.expanded {
  color: var(--onpoint-base);
}

.mobile-nav__content .main-menu__list>li>a>button,
.mobile-nav__content .main-menu__list>li>ul>li>a>button,
.mobile-nav__content .main-menu__list>li>ul>li>ul>li>a>button {
  width: 30px;
  height: 30px;
  background-color: var(--onpoint-base);
  border: none;
  outline: none;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transition: -webkit-transform 500ms ease;
  transition: -webkit-transform 500ms ease;
  transition: transform 500ms ease;
  transition: transform 500ms ease, -webkit-transform 500ms ease;
  padding: 0;
}

.mobile-nav__content .main-menu__list>li>a>button.expanded,
.mobile-nav__content .main-menu__list>li>ul>li>a>button.expanded,
.mobile-nav__content .main-menu__list>li>ul>li>ul>li>a>button.expanded {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  background-color: #fff;
  color: var(--onpoint-base);
}

/* no menu after 2rd level dropdown */
.mobile-nav__content .main-menu__list>li>ul>li>ul>li>a>button,
.mobile-nav__content .main-menu__list>li>ul>li>ul>li>ul {
  display: none !important;
}

.mobile-nav__content .main-menu__list li.cart-btn span {
  position: relative;
  top: auto;
  right: auto;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.mobile-nav__content .main-menu__list li.cart-btn i {
  font-size: 16px;
}

.mobile-nav__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 30px;
}

.mobile-nav__top .main-menu__login a {
  color: var(--onpoint-text-dark);
}

.mobile-nav__container {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.mobile-nav__social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.mobile-nav__social a {
  font-size: 16px;
  color: var(--onpoint-white);
  -webkit-transition: 500ms;
  transition: 500ms;
}

.mobile-nav__social a+a {
  margin-left: 30px;
}

.mobile-nav__social a:hover {
  color: var(--onpoint-base);
}

.mobile-nav__contact {
  margin-bottom: 0;
  margin-top: 20px;
  margin-bottom: 20px;
}

.mobile-nav__contact li {
  color: var(--onpoint-text-dark);
  font-size: 14px;
  font-weight: 500;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.mobile-nav__contact li+li {
  margin-top: 15px;
}

.mobile-nav__contact li a {
  color: #ffffff;
  -webkit-transition: 500ms;
  transition: 500ms;
}

.mobile-nav__contact li a:hover {
  color: var(--onpoint-base);
}

.mobile-nav__contact li>i {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--onpoint-base);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  font-size: 11px;
  margin-right: 10px;
  color: #fff;
}

.mobile-nav__container .main-logo,
.mobile-nav__container .topbar__buttons,
.mobile-nav__container .main-menu__language,
.mobile-nav__container .main-menu__login {
  display: none;
}

/*--------------------------------------------------------------
  # Navigations Two
  --------------------------------------------------------------*/

/*--------------------------------------------------------------
  # Search Popup
  --------------------------------------------------------------*/
.search-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  padding-left: 20px;
  padding-right: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: translateY(-110%);
  transform: translateY(-110%);
  -webkit-transition: opacity 500ms ease, -webkit-transform 500ms ease;
  transition: opacity 500ms ease, -webkit-transform 500ms ease;
  transition: transform 500ms ease, opacity 500ms ease;
  transition: transform 500ms ease, opacity 500ms ease, -webkit-transform 500ms ease;
}

.search-popup.active {
  -webkit-transform: translateY(0%);
  transform: translateY(0%);
}

.search-popup__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--onpoint-black);
  opacity: 0.75;
  cursor: pointer;
}

.search-popup__content {
  width: 100%;
  max-width: 560px;
}

.search-popup__content form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
  background-color: #fff;
  border-radius: 0;
  overflow: hidden;
}

.search-popup__content form input[type="search"],
.search-popup__content form input[type="text"] {
  width: 100%;
  background-color: #fff;
  font-size: 16px;
  border: none;
  outline: none;
  height: 66px;
  padding-left: 30px;
}

.search-popup__content .thm-btn {
  padding: 0;
  width: 68px;
  height: 68px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 0;
  right: -1px;
  border-radius: 0;
  background-color: var(--onpoint-base);
  border: 0;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.search-popup__content .thm-btn:after {
  background-color: var(--onpoint-black);
  border-radius: 0;
  transform: skew(21deg);
}

.search-popup__content .thm-btn i {
  height: auto;
  width: auto;
  background-color: transparent;
  border-radius: 50%;
  color: var(--onpoint-white);
  font-size: 22px;
  line-height: inherit;
  text-align: center;
  top: 0;
  margin-right: 0;
  padding-left: 0;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.search-popup__content .thm-btn:hover i {
  color: var(--onpoint-white);
}

/*--------------------------------------------------------------
  # Banner One
  --------------------------------------------------------------*/
.banner-one {
  position: relative;
  display: block;
  background-color: #0f1c25;
  background-image: url(../../../1wn-website/public/images/resources/banner-img-1.jpg);
  /* padding: 60px 0 0; */
  z-index: 1;
  height: calc(100vh - 100px);
  background-size: cover;
}

.banner-one::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #000000cf;
}

.banner-one__shape-1 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: .10;
  z-index: -1;
}

.banner-one__left {
  position: relative;
  display: block;
  height: 100%;
  display: table;
  width: 100%;
}

.banner-one__sub-title-box {
  position: relative;
  display: table-cell;
  vertical-align: middle;
}

.banner-one__sub-title-icon {
  position: relative;
  display: block;
  top: -2px;
}

.banner-one__sub-title-icon img {
  width: auto;
}

.banner-one__sub-title {
  position: relative;
  font-size: 18px;
  color: var(--onpoint-white);
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  text-align: center;
}

.banner-one__title {
  font-size: 80px;
  color: var(--onpoint-white);
  font-weight: 700;
  line-height: 95px;
  margin-top: 14px;
  margin-bottom: 21px;
}

.banner-one__title span {
  font-weight: 400;
}

.banner-one__btn-box {
  position: relative;
  display: block;
}

.banner-one__btn {
  border: none;
  padding: 6px 24px 6px;
  padding-right: 4px;
}

.banner-one__btn:hover {
  color: var(--onpoint-base);
}

.banner-one__btn::after {
  background-color: var(--onpoint-white);
}

.banner-one__btn>span {
  color: var(--onpoint-white);
}

.banner-one__btn:hover>span {
  background-color: rgba(var(--onpoint-black-rgb), .20);
  color: var(--onpoint-base);
  transition-delay: 300ms;
}

.banner-one__right {
  position: relative;
  display: flex;
  justify-content: end;
  margin-top: 22px;
}

.banner-one__img-box {
  position: relative;
  display: block;
}

.banner-one__img {
  position: relative;
  display: block;
  z-index: 2;
}

.banner-one__img img {
  width: 100%;
  border-radius: 20px;
}

.banner-one__content {
  position: relative;
  display: block;
  background-color: var(--onpoint-base);
  padding: 80px 0 36px;
  padding-left: 80px;
  margin-top: -50px;
}

.banner-one__call {
  position: relative;
  display: flex;
  align-items: center;
}

.banner-one__call-icon {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 1;
}

.banner-one__call-icon span {
  position: relative;
  display: inline-block;
  font-size: 65px;
  color: var(--onpoint-white);
}

.banner-one__call-number {
  margin-left: 30px;
}

.banner-one__call-number p {
  font-size: 24px;
  margin: 0;
  line-height: 24px;
  color: var(--onpoint-white);
  font-weight: 700;
  font-family: var(--onpoint-font-two);
  margin-bottom: 14px;
}

.banner-one__call-number h5 {
  font-size: 48px;
  line-height: 48px;
  font-weight: 700;
}

.banner-one__call-number h5 a {
  color: var(--onpoint-white);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.banner-one__call-number a:hover {
  color: var(--onpoint-black);
}

.banner-one__img-two {
  position: absolute;
  bottom: 0;
  left: -324px;
}

.banner-one__img-two img {
  width: auto;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

/*--------------------------------------------------------------
  # Why Choose One
  --------------------------------------------------------------*/
.why-choose-one {
  position: relative;
  display: block;
}

.why-choose-one__left {
  position: relative;
  display: block;
  margin-right: 160px;
}

.why-choose-one__left .section-title {
  margin-bottom: 16px;
}

.why-choose-one__btn-box {
  position: relative;
  display: block;
  margin-top: 28px;
}

.why-choose-one__right {
  position: relative;
  display: block;
}

.why-choose-one__img {
  position: relative;
  display: block;
}

.why-choose-one__img img {
  width: 100%;
  border-radius: 20px;
}

.why-choose-one__delivery-box {
  position: absolute;
  right: 10px;
  bottom: 10px;
  max-width: 304px;
  width: 100%;
  background: rgb(153, 153, 153);
  background: linear-gradient(0deg, rgba(153, 153, 153, 1) 0%, rgba(255, 255, 255, 1) 80%);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 23px;
  border-radius: 22px;
  padding: 31px 20px 31px;
  z-index: 1;
}

.why-choose-one__delivery-box::before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  background-color: var(--onpoint-base);
  border-radius: 22px;
  z-index: -1;
}

.why-choose-one__delivery-box .icon {
  position: relative;
  display: inline-block;
}

.why-choose-one__delivery-box .icon>span {
  position: relative;
  display: inline-block;
  font-size: 66px;
  color: var(--onpoint-white);
}

.why-choose-one__delivery-box:hover .icon>span {
  -webkit-animation: bounceright 0.3s alternate ease infinite;
  animation: bounceright 0.3s alternate ease infinite;
}

.why-choose-one__delivery-box>p {
  font-size: 22px;
  font-weight: 700;
  line-height: 27px;
  font-family: var(--onpoint-font-two);
  color: var(--onpoint-white);
}

/*--------------------------------------------------------------
  # Project One
  --------------------------------------------------------------*/
.project-one {
  position: relative;
  display: block;
  padding: 120px 0 110px;
  z-index: 1;
}

.project-one .container {
  max-width: 1572px;
}

.project-one .row {
  --bs-gutter-x: 10px;
}

.project-one__single {
  position: relative;
  display: block;
  margin-bottom: 10px;
}

.project-one__img-box {
  position: relative;
  display: block;
}

.project-one__img {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 20px;
  border: 1px solid #c3c3c3;
}

.project-one__img:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  pointer-events: none;
  opacity: 0;
  border-radius: 20px;
  transition: opacity .5s ease-out;
  background-color: rgba(var(--onpoint-black-rgb), .70);
}

.project-one__single:hover:hover .project-one__img:after {
  opacity: 1
}

.project-one__img img {
  width: 100%;
  border-radius: 20px;
  transform: scale(1.01);
  transition: transform .5s cubic-bezier(.27, .48, .45, .94);
  transform-origin: 70% 80%
}

.project-one__single:hover .project-one__img img {
  transform: scale(1.036);
  transition-duration: .9s
}

.project-one__content {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  background-color: var(--onpoint-white);
  border-radius: 20px;
  padding: 16px 27px 17px;
  border-bottom: 3px solid var(--onpoint-base);
  transform: scaleX(0.7) rotateX(20deg);
  opacity: 0;
  transition: all 0.4s linear;
  z-index: 2;
}

.project-one__single:hover .project-one__content {
  transform: scaleX(1.0) rotateX(0deg);
  transition: all 0.4s linear;
  opacity: 1;
}

.project-one__sub-title {
  font-size: 15px;
  font-weight: 700;
  line-height: 25px;
  font-family: var(--onpoint-font-two);
  color: var(--onpoint-black);
}

.project-one__title {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  text-transform: capitalize;
  margin-top: 2px;
}

.project-one__title a {
  color: var(--onpoint-black);
}

.project-one__title a:hover {
  color: var(--onpoint-base);
}

/*--------------------------------------------------------------
  # Brand One
  --------------------------------------------------------------*/
.brand-one {
  position: relative;
  display: block;
  z-index: 1;
}

.brand-one .container {
  max-width: 1350px;
}

.brand-one__text {
  font-family: var(--onpoint-font-two);
  text-align: center;
  color: var(--onpoint-black);
  margin-bottom: -20px;
  margin-top: 73px;
}

.brand-one .swiper-slide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 10px;
}

.brand-one .swiper-slide img {
  -webkit-transition: 500ms;
  transition: 500ms;
  max-width: 100%;
  opacity: 1;
  border: 1px solid #e3e4e9;
  margin: 26px;
  height: 100px;
  padding: 10px;
}

.brand-one .swiper-slide img:hover {
  opacity: .50;
}

/*--------------------------------------------------------------
  # About One
  --------------------------------------------------------------*/
.about-one {
  position: relative;
  display: block;
  padding: 100px 0 0px;
  z-index: 1;
}

.about-one__left {
  position: relative;
  display: block;
  margin-top: 40px;
}

.about-one__left .section-title {
  margin-bottom: 21px;
}

.about-one__list-box {
  position: relative;
  display: block;
  margin-top: 41px;
}

.about-one__list {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.about-one__list li {
  position: relative;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  width: 100%;
  margin-bottom: 25px;
}

.about-one__icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  background-color: var(--onpoint-extra);
  border-radius: 50%;
}

.about-one__icon span {
  position: relative;
  display: inline-block;
  font-size: 30px;
  color: var(--onpoint-base);
}

.about-one__list li h3 {
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  text-transform: capitalize;
  margin-top: 15px;
}

.about-one__btn-box {
  position: relative;
  display: block;
  margin-top: 15px;
}

.about-one__btn {
  color: var(--onpoint-black);
  background-color: var(--onpoint-white);
}

.about-one__btn>span {
  background-color: var(--onpoint-primary);
}

.about-one__btn:hover>span {
  color: var(--onpoint-black);
}

.about-one__shape-1 {
  position: absolute;
  left: 47px;
  top: 6px;
}

.about-one__shape-1 img {
  width: auto;
}

.about-one__right {
  position: relative;
  display: block;
  margin-left: 23px;
}

.about-one__img-box {
  position: relative;
  display: flex;
  align-items: center;
  gap: 30px;
}

.about-one__img-1 {
  position: relative;
  display: block;
  width: 100%;
}

.about-one__img-1 img {
  border-radius: 20px;
height: 520px;
}

.about-one__img-2 {
  position: relative;
  display: block;
  max-width: 332px;
  width: 100%;
}

.about-one__img-2 img {
  width: 100%;
  border-radius: 20px;
}

.about-one__trusted-box {
  position: absolute;
  left: 30px;
  bottom: 30px;
  background-color: var(--onpoint-base);
  display: flex;
  align-items: center;
  max-width: 383px;
  width: 100%;
  border: 2px solid var(--onpoint-white);
  border-radius: 20px;
  gap: 20px;
  padding: 28px 25px 24px;
  z-index: 1;
}

.about-one__trust-icon {
  position: relative;
  display: inline-block;
}

.about-one__trust-icon span {
  position: relative;
  display: inline-block;
  font-size: 50px;
  color: var(--onpoint-white);
  transition: all 500ms linear;
  transition-delay: 0.1s;
  transform: scale(1);
}

.about-one__trusted-box:hover .about-one__trust-icon span {
  transform: scale(0.9);
}

.about-one__trust-content {
  position: relative;
  display: flex;
  align-items: center;
  gap: 28px;
}

.about-one__trust-count {
  position: relative;
  display: flex;
  align-items: center;
}

.about-one__trust-count h3 {
  font-size: 48px;
  font-weight: 700;
  line-height: 48px;
  color: var(--onpoint-white);
}

.about-one__trust-count span {
  font-size: 48px;
  font-weight: 700;
  line-height: 48px;
  color: var(--onpoint-white);
  font-family: var(--onpoint-font-two);
}

.about-one__trust-plus {
  font-size: 48px;
  font-weight: 700;
  line-height: 48px;
  color: var(--onpoint-white);
  font-family: var(--onpoint-font-two);
}

.about-one__trust-text {
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  font-family: var(--onpoint-font-two);
  color: var(--onpoint-white);
}

/*--------------------------------------------------------------
  # Counter One
  --------------------------------------------------------------*/
.counter-one {
  position: relative;
  display: block;
}

.counter-one__left {
  position: relative;
  display: block;
}

.counter-one__img {
  position: relative;
  display: block;
}

.counter-one__img img {
  width: 100%;
  border-radius: 50px;
}

.counter-one__video-link {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1;
}

.counter-one__video-icon {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  font-size: 20px;
  color: var(--onpoint-white);
  background-color: var(--onpoint-base);
  border-radius: 50%;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.counter-one__video-icon:hover {
  background-color: var(--onpoint-white);
  color: var(--onpoint-base);
}

.counter-one__video-icon::before {
  content: "";
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  border: 5px solid #f29f2d;
  border-radius: 50%;
}

.counter-one__video-icon::after {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  border: 5px solid rgba(var(--onpoint-white-rgb), .50);
  border-radius: 50%;
}

.counter-one__video-link .ripple,
.counter-one__video-icon .ripple:before,
.counter-one__video-icon .ripple:after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -ms-box-shadow: 0 0 0 0 rgba(var(--onpoint-white-rgb), 0.6);
  -o-box-shadow: 0 0 0 0 rgba(var(--onpoint-white-rgb), 0.6);
  -webkit-box-shadow: 0 0 0 0 rgba(var(--onpoint-white-rgb), 0.6);
  box-shadow: 0 0 0 0 rgba(var(--onpoint-white-rgb), 0.6);
  -webkit-animation: ripple 3s infinite;
  animation: ripple 3s infinite;
}

.counter-one__video-icon .ripple:before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
  content: "";
  position: absolute;
}

.counter-one__video-icon .ripple:after {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  content: "";
  position: absolute;
}

.counter-one__right {
  position: relative;
  display: block;
  background-color: #f29f2d;
  border-radius: 50px;
  padding: 60px 45px 60px;
  padding-right: 30px;
}

.counter-one__right .section-title__tagline {
  color: var(--onpoint-black);
}

.counter-one__right .section-title {
  margin-bottom: 21px;
}

.counter-one__right-text {
  color: var(--onpoint-black);
}

.counter-one__count-list {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 35px;
}

.counter-one__count-list li {
  position: relative;
  display: block;
  background-color: #ffffff;
  border-radius: 20px;
  max-width: 207px;
  width: 100%;
  padding: 20px 20px 21px;
  padding-right: 10px;
  height: 215px;
}

.counter-one__icon-and-count {
  position: relative;
  display: flex;
  align-items: center;
  gap: 15px;
}

.counter-one__icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65px;
  height: 65px;
  background-color: var(--onpoint-base);
  border-radius: 20px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.counter-one__count-list li:hover .counter-one__icon {
  background-color: var(--onpoint-black);
}

.counter-one__icon span {
  position: relative;
  display: inline-block;
  font-size: 40px;
  color: var(--onpoint-white);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.counter-one__count-list li:hover .counter-one__icon span {
  color: var(--onpoint-primary);
}

.counter-one__count {
  position: relative;
  display: flex;
  align-items: center;
}

.counter-one__count>h3 {
  font-size: 48px;
  font-weight: 700;
  line-height: 48px;
}

.counter-one__count>span {
  font-size: 48px;
  font-weight: 700;
  line-height: 48px;
  font-family: var(--onpoint-font-two);
  color: var(--onpoint-black);
}

.counter-one__count-plus {
  font-size: 48px;
  font-weight: 700;
  line-height: 48px;
  font-family: var(--onpoint-font-two);
  color: var(--onpoint-black);
}

.counter-one__count-text {
  color: var(--onpoint-black);
  margin-top: 10px;
}

/*--------------------------------------------------------------
  # Team One
  --------------------------------------------------------------*/
.team-one {
  position: relative;
  display: block;
  padding: 120px 0 120px;
  z-index: 1;
}

.team-one__top {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 60px;
}

.team-one__top .section-title {
  margin-bottom: 0;
}

.team-one__bottom {
  position: relative;
  display: block;
}

.team-one__single {
  position: relative;
  display: flex;
  align-items: center;
  padding: 40px 20px 40px;
}

.team-one__single::before {
  content: "";
  position: absolute;
  top: 0;
  left: 75px;
  bottom: 0;
  right: 0;
  border: 1px solid var(--onpoint-bdr-color);
  border-radius: 20px;
}

.team-one__img {
  position: relative;
  display: block;
  max-width: 150px;
  width: 100%;
  overflow: hidden;
  border-radius: 50%;
  border: 2px solid var(--onpoint-bdr-color);
}

.team-one__img:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  pointer-events: none;
  opacity: 0;
  border-radius: 50%;
  transition: opacity .5s ease-out;
  background-color: rgba(var(--onpoint-black-rgb), .50);
}

.team-one__single:hover:hover .team-one__img:after {
  opacity: 1
}

.team-one__img img {
  width: 100%;
  border-radius: 50%;
  transform: scale(1.01);
  transition: transform .5s cubic-bezier(.27, .48, .45, .94);
  transform-origin: 70% 80%
}

.team-one__single:hover .team-one__img img {
  transform: scale(1.036);
  transition-duration: .9s
}


.team-one__content {
  position: relative;
  display: block;
  margin-left: 20px;
}

.team-one__title {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  text-transform: capitalize;
}

.team-one__title a {
  color: var(--onpoint-black);
}

.team-one__title a:hover {
  color: var(--onpoint-base);
}

.team-one__sub-title {
  margin-top: 5px;
  margin-bottom: 21px;
}

.team-one__social {
  position: relative;
  display: flex;
  align-items: center;
}

.team-one__social a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: var(--onpoint-extra);
  border-radius: 50%;
  font-size: 13px;
  color: var(--onpoint-black);
}

.team-one__social a:hover {
  background-color: var(--onpoint-base);
  color: var(--onpoint-white);
}

.team-one__social a+a {
  margin-left: 10px;
}

.team-one__nav {
  position: relative;
  display: flex;
  align-items: center;
}

.team-one__nav .swiper-button-prev1,
.team-one__nav .swiper-button-next1 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border: 0;
  border-radius: 50%;
  color: var(--onpoint-base);
  background-color: rgba(var(--onpoint-base-rgb), .10);
  font-size: 16px;
  margin: 0px 0px;
  text-align: center;
  transition: all 500ms ease;
  z-index: 100;
}

.team-one__nav .swiper-button-prev1:hover,
.team-one__nav .swiper-button-next1:hover {
  color: var(--onpoint-white);
  background-color: var(--onpoint-base);
}

.team-one__nav .swiper-button-next1 {
  margin-right: 20px;
}

/*--------------------------------------------------------------
  # Why Are We
  --------------------------------------------------------------*/
.why-are-we {
  position: relative;
  display: block;
  padding: 100px 0 0px;
  z-index: 1;
}

.why-are-we__left {
  position: relative;
  display: block;
  margin-right: 57px;
}

.why-are-we__img {
  position: relative;
  display: block;
}

.why-are-we__img img {
  width: 100%;
  border-radius: 20px;
}

.why-are-we__right {
  position: relative;
  display: block;
  margin-left: -57px;
}

.why-are-we__right .section-title {
  margin-bottom: 31px;
}

.why-are-we__list {
  position: relative;
  display: flex;
  align-items: center;
}

.why-are-we__list li {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.why-are-we__list li+li {
  margin-left: 105px;
}

.why-are-we__list li .icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 50px;
  width: 100%;
  height: 50px;
  background-color: var(--onpoint-base);
  border-radius: 50%;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.why-are-we__list li:hover .icon {
  background-color: var(--onpoint-black);
}

.why-are-we__list li .icon span {
  position: relative;
  display: inline-block;
  font-size: 20px;
  color: var(--onpoint-white);
  transition: .2s;
  transform-origin: 50% 60%;
}

.why-are-we__list li:hover .icon span {
  color: var(--onpoint-white);
}

.why-are-we__list li:hover .why-are-we__call {
  -webkit-animation: wiggle .05s alternate ease infinite;
  animation: wiggle .05s alternate ease infinite;
}

.why-are-we__list li:hover .why-are-we__location {
  -webkit-animation: none;
  -webkit-transform: scale(1.4);
  animation: none;
  transform: scale(1.4);
}

.why-are-we__list li:hover .why-are-we__cart {
  -webkit-animation: none;
  -webkit-transform: scale(1.4);
  animation: none;
  transform: scale(1.4);
}

.why-are-we__list li .content {
  position: relative;
  display: block;
  margin-left: 15px;
}

.why-are-we__list li .content h3 {
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  text-transform: capitalize;
}

.why-are-we__list li .content p {
  font-size: 14px;
  line-height: 22px;
  margin-top: 5px;
}

.why-are-we__icon {
  position: absolute;
  top: 15px;
  right: -74px;
}

.why-are-we__icon span {
  position: relative;
  display: inline-block;
  font-size: 38px;
  color: var(--onpoint-primary);
}

.why-are-we__icon-2 {
  position: absolute;
  top: 13px;
  right: -73px;
}

.why-are-we__icon-2 span {
  position: relative;
  display: inline-block;
  font-size: 38px;
  color: var(--onpoint-primary);
}

.why-are-we__img-2 {
  position: relative;
  display: block;
  margin-top: 36px;
}

.why-are-we__img-2 img {
  width: 100%;
  border-radius: 20px;
}

.why-are-we__year {
  position: absolute;
  left: -383px;
  bottom: 15px;
  max-width: 616px;
  background-color: #f29f2d;
  border-radius: 20px;
  padding: 45px 40px 56px;
}

.why-are-we__year>h3 {
  color: var(--onpoint-black);
  font-size: 48px;
  line-height: 48px;
  font-weight: 700;
  text-transform: uppercase;


}

.why-are-we__year>p {
  color: var(--onpoint-black);
}

/*--------------------------------------------------------------
  # FAQ One
  --------------------------------------------------------------*/
.faq-one {
  position: relative;
  display: block;
  padding: 80px 0 0;
  z-index: 1;
}

.faq-one__bg-color {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 85px;
  background-color: var(--onpoint-black);
}

.faq-one__left {
  position: relative;
  display: block;
}

.faq-one__left .section-title {
  margin-bottom: 30px;
}

.faq-one__left .section-title__tagline {
  color: var(--onpoint-white);
}

.faq-one__left .section-title__title {
  color: var(--onpoint-white);
}

.faq-one__left .faq-one-accrodion .accrodion {
  position: relative;
  display: block;
  background-color: rgba(var(--onpoint-white-rgb), 0.05);
  border: 1px solid transparent;
  border-radius: 20px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.faq-one__left .faq-one-accrodion .accrodion.active {
  border: 1px solid rgba(204, 204, 204, .50);
  background-color: transparent;
}

.faq-one__left .faq-one-accrodion .accrodion-title {
  position: relative;
  display: block;
  cursor: pointer;
  padding: 23px 70px 24px;
  padding-right: 20px;
  transition: all 200ms linear;
  transition-delay: 0.1s;
  padding-right: 25px;
}

.faq-one__left .faq-one-accrodion .accrodion-title h4 {
  margin: 0;
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  text-transform: capitalize;
  color: var(--onpoint-white);
  position: relative;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.faq-one__left .faq-one-accrodion .accrodion.active .accrodion-title h4 {
  color: var(--onpoint-white);
}

.faq-one__left .faq-one-accrodion .accrodion+.accrodion {
  margin-top: 20px;
}

.faq-one__left .faq-one-accrodion .accrodion-title h4::before {
  content: "\f067";
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  font-size: 13px;
  color: var(--onpoint-white);
  position: absolute;
  top: 50%;
  left: -50px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--onpoint-base);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  text-align: center;
}

.faq-one__left .faq-one-accrodion .accrodion.active .accrodion-title h4::before {
  content: "\f068";
  color: var(--onpoint-white);
  background-color: rgba(var(--onpoint-white-rgb), .20);
}

.faq-one__left .faq-one-accrodion .accrodion-content {
  position: relative;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 1px;
}

.faq-one__left .faq-one-accrodion .accrodion-content p {
  margin: 0;
  color: var(--onpoint-white);
  border-top: 1px solid rgba(var(--onpoint-white-rgb), .50);
  padding-top: 20px;
}

.faq-one__right {
  position: relative;
  display: block;
  background-color: var(--onpoint-base);
  border-radius: 20px;
  padding: 58px 40px 60px;
}

.faq-one__from-title {
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  color: var(--onpoint-white);
  margin-bottom: 38px;
}

.faq-one__form {
  position: relative;
  display: block;
}

.faq-one__input-box {
  position: relative;
  display: block;
  margin-bottom: 30px;
}

.faq-one__input-box input[type="text"],
.faq-one__input-box input[type="email"] {
  height: 67px;
  width: 100%;
  border: none;
  padding-left: 20px;
  padding-right: 20px;
  outline: none;
  font-size: 18px;
  color: var(--onpoint-black);
  display: block;
  font-weight: 400;
  border-radius: 20px;
  /* background-color: rgba(var(--onpoint-white-rgb), .10); */
}

.faq-one__input-box .select-box {
  width: 100%;
}

.faq-one__input-box .nice-select {
  height: 67px;
  width: 100%;
  border: none;
  padding-left: 20px;
  padding-right: 20px;
  outline: none;
  font-size: 18px;
  color: var(--onpoint-black);
  display: block;
  font-weight: 400;
  border-radius: 20px;
  line-height: 67px;
  float: none;
  background-color: var(--onpoint-white);
}

.faq-one__input-box .nice-select:after {
  position: absolute;
  top: 28px;
  right: 20px;
  margin-top: 0px;
  z-index: 10;
  border-bottom: 2px solid var(--onpoint-white);
  border-right: 2px solid var(--onpoint-white);
}

.faq-one__input-box .nice-select .option {
  color: var(--onpoint-white);
}

.faq-one__input-box textarea {
  position: relative;
  height: 150px;
  width: 100%;
  border: none;
  padding-top: 15px;
  padding-left: 20px;
  padding-right: 20px;
  outline: none;
  font-size: 14px;
  color: var(--onpoint-black);
  display: block;
  font-weight: 400;
  border-radius: 20px;
  margin-bottom: 0;
  /* background-color: rgba(var(--onpoint-white-rgb), .10); */
}

.faq-one__input-box.text-message-box {
  margin-bottom: 40px;
}

.faq-one__btn-box {
  position: relative;
  display: block;
}

.faq-one__btn {
  border: none;
  color: var(--onpoint-black);
  background-color: var(--onpoint-white);
}

/*--------------------------------------------------------------
  # Testimonial One
  --------------------------------------------------------------*/
.testimonial-one {
  position: relative;
  display: block;
  padding: 100px 0 0;
  z-index: 1;
}

.testimonial-one__inner {
  position: relative;
  display: block;
  /* padding: 0 0 170px; */
}
.testimonial-banner-block{
  padding: 75px 0;
}

.testimonial-one__img-one {
  position: absolute;
  bottom: -75px;
  left: 0;
}

.testimonial-one__img-one img {
  width: auto;
  border-radius: 20px;
}

.testimonial-one__inner .section-title {
  margin-bottom: 126px;
}

.testimonial-one__right {
  position: relative;
  display: block;
}

.testimonial-one__single {
  position: relative;
  display: block;
  background: #FFFFFF;
  box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding: 40px 60px 119px;
}

.testimonial-one__ratting {
  position: relative;
  display: flex;
  align-items: center;
}

.testimonial-one__ratting>span {
  position: relative;
  display: inline-block;
  font-size: 21px;
  color: #F3DC65;
}

.testimonial-one__ratting>span+span {
  margin-left: 7px;
}

.testimonial-one__ratting .last-star {
  color: #CCCCCC;
}

.testimonial-one__client-name {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  margin-top: 14px;
}

.testimonial-one__text {
  font-size: 20px;
  line-height: 34px;
  border-top: 1px solid var(--onpoint-bdr-color);
  margin-top: 15px;
  padding-top: 20px;
}

.testimonial-one__right .swiper-container {
  overflow: visible;
}

.testimonial-one__right .swiper-container .swiper-slide {
  opacity: 0;
  visibility: hidden;
  transition: opacity 500ms ease, visibility 500ms ease;
}

.testimonial-one__right .swiper-container .swiper-slide-active.swiper-slide {
  opacity: 1;
  visibility: visible;
}

.testimonial-one__quote {
  position: absolute;
  top: 40px;
  right: 40px;
}

.testimonial-one__quote span {
  position: relative;
  display: inline-block;
  font-size: 95px;
  color: rgba(var(--onpoint-base-rgb), 0.05);
}

.testimonial-one__client-img {
  position: absolute;
  top: 57px;
  right: 60px;
  border: 1px solid var(--onpoint-bdr-color);
  border-radius: 50%;
}

.testimonial-one__client-img img {
  width: auto;
  border-radius: 50%;
}

.testimonial-one__dot-style {
  position: absolute;
  bottom: 244px;
  right: 606px;
  z-index: 2;
}

.testimonial-one__inner .swiper-dot-style1 {
  position: relative;
  display: inline-flex;
  line-height: 0;
  height: 0;
}

.testimonial-one__inner .swiper-dot-style1 .swiper-pagination-bullet {
  position: relative;
  display: block;
  width: 10px;
  height: 10px;
  margin: 0px 5px;
  background: #CCCCCC;
  border-radius: 50%;
  opacity: 1;
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.testimonial-one__inner .swiper-dot-style1 .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--onpoint-base);
  opacity: 1.0;
}

.testimonial-one__inner .swiper-dot-style1 .swiper-pagination-bullet::before {
  position: absolute;
  top: -5px;
  left: -5px;
  bottom: -5px;
  right: -5px;
  background-color: var(--onpoint-base);
  border-radius: 50%;
  transform: scale(0);
  transition: all 200ms linear;
  transition-delay: 0.1s;
  content: "";
}

.testimonial-one__inner .swiper-dot-style1 .swiper-pagination-bullet.swiper-pagination-bullet-active::before,
.testimonial-one__inner .swiper-dot-style1 .swiper-pagination-bullet:hover {
  transform: scale(1.0);
}

/*--------------------------------------------------------------
  # Blog One
  --------------------------------------------------------------*/
.blog-one {
  position: relative;
  display: block;
  padding: 120px 0 240px;
  background-color: var(--onpoint-extra);
  z-index: 1;
}

.blog-one__single {
  position: relative;
  display: block;
  background-color: var(--onpoint-white);
  border-radius: 20px;
  padding: 30px 30px 29px;
  margin-bottom: 30px;
}

.blog-one__img-box {
  position: relative;
  display: block;
}

.blog-one__img {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 20px;
}

.blog-one__img::before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px;
  background: var(--onpoint-black);
  opacity: 0;
  transition: background-color 0.5s ease;
  transition: all 0.5s ease;
  z-index: 1;
}

.blog-one__single:hover .blog-one__img::before {
  opacity: .50;
}

.blog-one__img img {
  width: 100%;
  border-radius: 20px;
  transform: scale3d(1, 1, 1);
  transition: transform 1s ease-in-out;
}

.blog-one__single:hover .blog-one__img img {
  transform: scale(1.05) rotate(0deg);
}

.blog-one__date {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.blog-one__date p {
  font-size: 15px;
  font-weight: 700;
  line-height: 15px;
  width: 87px;
  height: 38px;
  background-color: var(--onpoint-base);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--onpoint-white);
  font-family: var(--onpoint-font-two);
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.blog-one__content {
  position: relative;
  display: block;
  padding-top: 30px;
}

.blog-one__title {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
}

.blog-one__title>a {
  color: var(--onpoint-black);
}

.blog-one__title>a:hover {
  color: var(--onpoint-base);
}

.blog-one__text {
  margin-top: 20px;
  margin-bottom: 30px;
}

.blog-one__read-more {
  position: relative;
  display: inline-block;
}

.blog-one__read-more>a {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 700;
  line-height: 25px;
  font-family: var(--onpoint-font-two);
  color: var(--onpoint-black);
  gap: 10px;
}

.blog-one__read-more>a:hover {
  color: var(--onpoint-base);
}

.blog-one__read-more>a>span {
  position: relative;
  font-size: 12px;
}

/*--------------------------------------------------------------
  # CTA One
  --------------------------------------------------------------*/
.cta-one {
  position: relative;
  display: block;
  z-index: 2;
  margin-top: 100px;
}

.cta-one__inner {
  position: relative;
  display: block;
  border-radius: 20px;
  padding: 52px 60px 50px;
  z-index: 1;
}

.cta-one__inner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px;
  background: linear-gradient(89.46deg, #f29f2d 49.9%, rgba(91, 9, 17, 0) 99.53%);
  z-index: 1;
}

.cta-one__bg-img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: calc((100% - 40px) / 2);
  background-size: cover;
  background-position: top right;
  background-repeat: no-repeat;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  z-index: -1;
}

.cta-one__content-box {
  position: relative;
  display: block;
  z-index: 2;
}

.cta-one__icon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65px;
  height: 65px;
  top: 50%;
  left: 45%;
  border: 1px solid var(--onpoint-white);
  transform: translateX(-50%) translateY(-50%);
  border-radius: 50%;
}

.cta-one__icon:hover {
  border: 1px solid var(--onpoint-black);
  background-color: var(--onpoint-black);
}

.cta-one__icon span {
  position: relative;
  font-size: 21px;
  color: var(--onpoint-white);
  transition: .2s;
  transform-origin: 50% 60%;
}

.cta-one__icon:hover>span {
  -webkit-animation: wiggle .05s alternate ease infinite;
  animation: wiggle .05s alternate ease infinite;
}

.cta-one__shape-1 {
  position: absolute;
  top: -2px;
  left: -114px;
  animation: leftRight 4s ease-in-out infinite;
}

.cta-one__shape-1 img {
  width: auto;
  opacity: .20;
}

.cta-one__title {
  font-size: 48px;
  font-weight: 700;
  line-height: 48px;
  color: var(--onpoint-white);
  margin-bottom: 19px;
}

.cta-one__contact-box {
  position: relative;
  display: flex;
  align-items: center;
  gap: 15px;
  padding-top: 50px;
}

.cta-one__contact-box .icon {
  position: relative;
  display: inline-block;
}

.cta-one__contact-box .icon>span {
  position: relative;
  display: inline-block;
  font-size: 55px;
  color: var(--onpoint-white);
  transition: all 500ms linear;
  transition-delay: 0.1s;
  transform: scale(1);
}

.cta-one__contact-box:hover .icon span {
  transform: scale(0.9);
}

.cta-one__contact-box .content {
  position: relative;
  display: block;
}

.cta-one__contact-box .content>p {
  color: var(--onpoint-white);
}

.cta-one__contact-box .content>h3 {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
}

.cta-one__contact-box .content>h3>a {
  color: var(--onpoint-white);
}

.cta-one__contact-box .content>h3>a:hover {
  color: var(--onpoint-black);
}

/*--------------------------------------------------------------
  # Site Footer
  --------------------------------------------------------------*/
.site-footer {
  position: relative;
  display: block;
  background-color: #254671;
  margin-top: -124px;
  z-index: 1;
}

.site-footer__shape-1 {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
}

.site-footer__shape-1 img {
  width: auto;
  opacity: .10;
}

.site-footer__top {
  position: relative;
  display: block;
  padding: 204px 0 80px;
}

.site-footer__top-inner {
  position: relative;
  display: block;
}

.footer-widget__about {
  position: relative;
  display: block;
  margin-right: -5px;
}

.footer-widget__logo {
  position: relative;
  display: block;
}

.footer-widget__about-text {
  color: rgba(var(--onpoint-white-rgb), .90);
  margin-top: 30px;
  margin-bottom: 31px;
}

.footer-widget__about-contact {
  position: relative;
  display: block;
}

.footer-widget__about-contact li {
  position: relative;
  display: flex;
  align-items: center;
}

.footer-widget__about-contact li+li {
  margin-top: 20px;
}

.footer-widget__about-contact li .icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(var(--onpoint-base-rgb), .10);
  border: 1px solid rgba(var(--onpoint-white-rgb), .10);
}

.footer-widget__about-contact li .icon>span {
  position: relative;
  display: inline-block;
  font-size: 16px;
  color: var(--onpoint-base);
  transition: .2s;
  transform-origin: 50% 60%;
}

.footer-widget__about-contact li:hover .location-icon {
  -webkit-animation: none;
  -webkit-transform: scale(1.4);
  animation: none;
  transform: scale(1.4);
}

.footer-widget__about-contact li p {
  margin-left: 15px;
  color: rgba(var(--onpoint-white-rgb), .80);
}

.footer-widget__about-contact li p>a {
  color: rgba(var(--onpoint-white-rgb), .80);
}

.footer-widget__about-contact li p>a:hover {
  color: var(--onpoint-base);
}

.footer-widget__about-contact li:hover .phone-call {
  -webkit-animation: wiggle .05s alternate ease infinite;
  animation: wiggle .05s alternate ease infinite;
}

.footer-widget__about-contact li:hover .email-icon {
  -webkit-animation: none;
  -webkit-transform: skew(-20deg);
  animation: none;
  transform: skew(-20deg);
  text-indent: .1em;
}

.footer-widget__company {
  position: relative;
  display: block;
  margin-left: 81px;
}

.footer-widget__title-box {
  position: relative;
  display: block;
  margin-bottom: 33px;
  margin-top: -1px;
}

.footer-widget__title {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  color: var(--onpoint-white);
}

.footer-widget__company-list {
  position: relative;
  display: block;
}

.footer-widget__services-list li {
  position: relative;
  display: block;
}

.footer-widget__company-list li+li {
  margin-top: 17px;
}

.footer-widget__company-list li>a {
  position: relative;
  display: inline-block;
  color: rgba(var(--onpoint-white-rgb), .90);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.footer-widget__company-list li>a:hover {
  color: var(--onpoint-base);
}

.footer-widget__services {
  position: relative;
  display: block;
  margin-left: 17px;
}

.footer-widget__services-list {
  position: relative;
  display: block;
}

.footer-widget__services-list li {
  position: relative;
  display: block;
}

.footer-widget__services-list li+li {
  margin-top: 16px;
}

.footer-widget__services-list li>a {
  position: relative;
  display: flex;
  align-items: center;
  gap: 16px;
  color: rgba(var(--onpoint-white-rgb), .80);
}

.footer-widget__services-list li>a:hover {
  color: var(--onpoint-base);
}

.footer-widget__services-list li>a>span {
  position: relative;
  font-size: 15px;
  color: var(--onpoint-base);
}

.footer-widget__newsletter {
  position: relative;
  display: block;
}

.footer-widget__newsletter-text {
  color: rgba(var(--onpoint-white-rgb), .90);
  margin-bottom: 25px;
}

.footer-widget__newsletter-form-input-box {
  position: relative;
  display: block;
}

.footer-widget__newsletter-form-input-box input[type="email"] {
  height: 60px;
  width: 100%;
  background-color: transparent;
  outline: none;
  font-size: 18px;
  color: var(--onpoint-white);
  font-weight: 400;
  padding-right: 90px;
  padding-left: 20px;
  border: 1px solid rgba(204, 204, 204, .20);
  border-radius: 20px;
}

.footer-widget__newsletter-btn {
  position: absolute;
  top: 8px;
  right: 0;
  bottom: 0;
  border: none;
  width: 68px;
  height: 60px;
  background-color: #f39f2f;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.footer-widget__newsletter-btn::after {

    content: "";
    background-color: var(--onpoint-black);
    position: absolute;
    transition: all .3s ease-in;
    z-index: -1;
    height: 100%;
    left: -50%;
    top: 0;
    transform: skew(30deg);
    transition-duration: .6s;
    transform-origin: top left;
    width: 0;
    z-index: 33;

}
.footer-widget__newsletter-btn:hover::after {
  height: 100%;
  width: 155%;
}
.footer-widget__newsletter-btn:hover .icon-paper-plane {
  color: #fff;
}

.footer-widget__newsletter-btn span {
  position: relative;
  display: inline-block;
  font-size: 16px;
  color: var(--onpoint-white);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 35;
}

.footer-widget__newsletter-btn:hover span {
  color: var(--onpoint-base);
}

.site-footer__bottom {
  position: relative;
  display: block;
}

.site-footer__bottom-inner {
  position: relative;
  /* display: flex; */
  align-items: center;
  flex-wrap: wrap;
  padding: 31px 30px 31px;
  background-color: rgba(var(--onpoint-white-rgb), 0.03);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.site-footer__bottom-text {
  color: rgba(var(--onpoint-white-rgb), .80);
}

.site-footer__bottom-text a {
  color: #f39f2f;
}

.site-footer__bottom-menu {
  position: relative;
  display: flex;
  align-items: center;
}

.site-footer__bottom-menu li {
  position: relative;
  display: block;
}

.site-footer__bottom-menu li+li {
  margin-left: 30px;
}

.site-footer__bottom-menu li a {
  position: relative;
  display: flex;
  align-items: center;
  color: rgba(var(--onpoint-white-rgb), .80);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.site-footer__bottom-menu li a:hover {
  color: var(--onpoint-base);
}

/*--------------------------------------------------------------
  # Services Two
  --------------------------------------------------------------*/
.services-two {
  position: relative;
  display: block;
  padding: 100px 0 0px;
  z-index: 1;
}

.services-two .section-title {
  margin-bottom: 100px;
}

.services-two__single {
  position: relative;
  display: block;
  border: 1px solid var(--onpoint-bdr-color);
  border-radius: 20px;
  padding: 49px 23px 40px;
  margin-bottom: 62px;
  min-height: 200px;
  max-height: 215px;
}

.services-two__title {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  margin-bottom: 7px;
}

.services-two__title>a {
  color: var(--onpoint-black);
}

.services-two__title>a:hover {
  color: var(--onpoint-base);
}

.services-two__icon {
  position: absolute;
  top: -40px;
  left: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  background-color: var(--onpoint-base);
  border-radius: 50%;
  overflow: hidden;
  z-index: 1;
}

.services-two__icon::before {
  transition-duration: 800ms;
  position: absolute;
  width: 200%;
  height: 200%;
  content: "";
  top: 110%;
  left: 50%;
  background-color: #244770;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  border-radius: 50%;
  z-index: -1;
}

.services-two__single:hover .services-two__icon::before {
  top: -40%;
}

.services-two__icon span {
  position: relative;
  display: inline-block;
  font-size: 40px;
  color: var(--onpoint-white);
  transition: all 500ms linear;
  transition-delay: 0.1s;
  transform: scale(1);
}

.services-two__single:hover .services-two__icon span {
  transform: scale(0.9);
}

.services-two__icon img {
  max-width: 55%;
}

/*--------------------------------------------------------------
  # Process One
  --------------------------------------------------------------*/
.process-one {
  position: relative;
  display: block;
  background-color: var(--onpoint-extra);
  counter-reset: count;
  padding: 120px 0 90px;
  z-index: 1;
}

.process-one .section-title {
  margin-bottom: 130px;
}

.process-one__single {
  position: relative;
  display: block;
  background-color: var(--onpoint-white);
  border-radius: 20px;
  padding: 37px 30px 27px;
  margin-bottom: 30px;
  height: 100%;
  text-align: justify;
}

.process-one__shape-1 {
  position: absolute;
  top: -68px;
  right: -98px;
}

.process-one__shape-1 img {
  width: auto;
}

.process-one__title {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  margin-bottom: 16px;
}

.process-one__icon-and-text {
  position: relative;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}

.process-one__icon-and-text>.icon {
  position: relative;
  display: inline-block;
}

.process-one__icon-and-text>.icon>span {
  position: relative;
  display: inline-block;
  font-size: 63px;
  color: var(--onpoint-base);
  transition: all 500ms linear;
  transition-delay: 0.1s;
  transform: scale(1);
}

.process-one__single:hover .process-one__icon-and-text>.icon>span {
  transform: scale(0.9);
}

.process-one__count {
  position: absolute;
  right: 30px;
  top: 2px;
}

.process-one__count:before {
  position: relative;
  display: block;
  font-size: 48px;
  line-height: 48px;
  font-weight: 700;
  counter-increment: count;
  content: "0" counter(count);
  transition: all 200ms linear;
  transition-delay: 0.1s;
  color: rgba(243, 159, 47, .5);
  font-family: var(--onpoint-font-two);
}

/*--------------------------------------------------------------
  # Google Map One
  --------------------------------------------------------------*/
.google-map-one {
  position: relative;
  display: block;
}

.google-map__one {
  position: relative;
  display: block;
  border: none;
  height: 525px;
  width: 100%;
}



/*--------------------------------------------------------------
  # Page Header
  --------------------------------------------------------------*/
.page-header {
  position: relative;
  display: block;
  /* padding: 141px 0 151px; */
  margin: 0 30px 0;
  overflow: hidden;
  z-index: 1;
  height: 350px;
  display: flex;
  align-items: center;
}

.page-header__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -1;
  margin: 30px 0;
}

.page-header__bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.87) 24.4%, rgba(102, 102, 102, 0) 100%);
  border-radius: 50px;
}

.page-header__inner {
  position: relative;
  display: block;
}

.page-header__inner h2 {
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  color: var(--onpoint-white);
}

.thm-breadcrumb__box {
  position: relative;
  display: inline-block;
  z-index: 1;
}

.thm-breadcrumb {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
}

.thm-breadcrumb li {
  position: relative;
  display: inline-block;
  font-size: 15px;
  font-weight: 700;
  color: var(--onpoint-white);
  font-family: var(--onpoint-font-two);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.thm-breadcrumb li a {
  position: relative;
  display: inline-block;
  color: var(--onpoint-white);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.thm-breadcrumb li:hover a {
  color: var(--onpoint-base);
}

.thm-breadcrumb li span {
  position: relative;
  font-size: 12px;
  color: var(--onpoint-base);
}



/*--------------------------------------------------------------
  # Google Map Two
  --------------------------------------------------------------*/
.google-map-two .google-map__one {
  height: 576px;
}

/*--------------------------------------------------------------
  # Blog Details  
  --------------------------------------------------------------*/
.blog-details {
  position: relative;
  display: block;
  padding: 120px 0 245px;
  z-index: 1;
}

.blog-details__left {
  position: relative;
  display: block;
}

.blog-details__img {
  position: relative;
  display: block;
}

.blog-details__img img {
  width: 100%;
  border-radius: 20px;
}

.blog-details__content {
  position: relative;
  display: block;
  margin-top: 20px;
}

.blog-details__meta {
  position: relative;
  display: flex;
  align-items: center;
  gap: 17px;
}

.blog-details__meta li {
  position: relative;
  display: block;
}

.blog-details__meta li>a {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: var(--onpoint-gray);
  gap: 10px;
}

.blog-details__meta li>a>span {
  color: var(--onpoint-black);
}

.blog-details__title-1 {
  font-size: 48px;
  font-weight: 700;
  line-height: 58px;
  margin-top: 16px;
  margin-bottom: 15px;
}

.blog-details__text-2 {
  margin-top: 31px;
  margin-bottom: 30px;
}

.blog-details__client-box {
  position: relative;
  display: block;
  border: 1px solid var(--onpoint-bdr-color);
  border-radius: 20px;
  padding: 32px 108px 30px;
  padding-right: 30px;
}

.blog-details__client-name {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  margin-bottom: 5px;
  margin-top: 21px;
}

.blog-details__quote {
  position: absolute;
  top: 40px;
  left: 35px;
}

.blog-details__quote span {
  position: relative;
  display: inline-block;
  font-size: 44px;
  color: var(--onpoint-base);
}

.blog-details__client-ratting {
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 52px;
  right: 30px;
}

.blog-details__client-ratting span {
  position: relative;
  display: inline-block;
  font-size: 20px;
  color: #F3DC65;
}

.blog-details__client-ratting span+span {
  margin-left: 7px;
}

.blog-details__client-ratting .icon-star-two {
  color: #CCCCCC;
}

.blog-details__title-2 {
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  margin-top: 38px;
  margin-bottom: 18px;
}

.blog-details__points-box {
  position: relative;
  display: flex;
  align-items: center;
  gap: 60px;
  margin-top: 31px;
  margin-bottom: 41px;
}

.blog-details__points-list {
  position: relative;
  display: block;
}

.blog-details__points-list li {
  position: relative;
  display: flex;
  align-items: center;
  gap: 15px;
}

.blog-details__points-list li+li {
  margin-top: 16px;
}

.blog-details__points-list li .icon {
  position: relative;
  display: inline-block;
  top: -1px;
}

.blog-details__points-list li .icon span {
  position: relative;
  display: inline-block;
  font-size: 11px;
  color: var(--onpoint-base);
}

.blog-details__img-box {
  position: relative;
  display: flex;
  align-items: center;
  gap: 30px;
}

.blog-details__img-box-img {
  position: relative;
  display: block;
  margin-bottom: 30px;
}

.blog-details__img-box-img img {
  width: auto;
  border-radius: 20px;
}

.blog-details__tag-and-social {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid var(--onpoint-bdr-color);
  border-bottom: 1px solid var(--onpoint-bdr-color);
  padding: 20px 0 20px;
  margin-top: 40px;
  margin-bottom: 30px;
}

.blog-details__tag-box {
  position: relative;
  display: flex;
  align-items: center;
}

.blog-details__tag-box>span {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  font-family: var(--onpoint-font-two);
  color: var(--onpoint-black);
  margin-right: 35px;
}

.blog-details__tag-box a {
  color: var(--onpoint-gray);
  padding: 4px 10px 5px;
  border-radius: 5px;
  border: 1px solid var(--onpoint-bdr-color);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.blog-details__tag-box a:hover {
  border: 1px solid var(--onpoint-base);
  background-color: var(--onpoint-base);
  color: var(--onpoint-white);
}

.blog-details__tag-box a+a {
  margin-left: 15px;
}

.blog-details__social {
  position: relative;
  display: flex;
  align-items: center;
}

.blog-details__social a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  font-size: 16px;
  color: var(--onpoint-black);
  border: 1px solid var(--onpoint-bdr-color);
  border-radius: 50%;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.blog-details__social a:hover {
  border: 1px solid var(--onpoint-primary);
  background-color: var(--onpoint-primary);
  color: var(--onpoint-black);
}

.blog-details__social a+a {
  margin-left: 10px;
}

.blog-details__pagenation-box {
  position: relative;
  display: block;
  overflow: hidden;
}

.blog-details__pagenation {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--onpoint-bdr-color);
  padding: 30px 30px 30px;
  border-radius: 20px;
}

.blog-details__pagenation::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  height: 50px;
  width: 1px;
  background-color: #CCCCCC;
  transform: translateX(-50%) translateY(-50%);
}

.blog-details__pagenation li {
  position: relative;
  display: flex;
  align-items: center;
}

.blog-details__pagenation li .icon {
  position: relative;
  display: inline-block;
}

.blog-details__pagenation li .icon>a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: var(--onpoint-extra);
  border: 1px solid var(--onpoint-bdr-color);
  font-size: 15px;
  color: var(--onpoint-black);
  border-radius: 50%;
}

.blog-details__pagenation li:hover .icon>a {
  background-color: var(--onpoint-primary);
  border: 1px solid var(--onpoint-primary);
}

.blog-details__pagenation li p {
  position: relative;
  margin-left: 20px;
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  color: var(--onpoint-black);
  font-family: var(--onpoint-font-two);
}

.blog-details__pagenation li:last-child>p {
  margin-left: 0px;
  margin-right: 20px;
}

/*--------------------------------------------------------------
  # Sidebar
  --------------------------------------------------------------*/
@media (max-width: 991px) {
  .sidebar {
    margin-top: 50px;
  }
}

.sidebar__single+.sidebar__single {
  margin-top: 60px;
}

.sidebar {
  position: relative;
  display: block;
}

.sidebar__title {
  position: relative;
  display: block;
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 29px;
}

.sidebar__search {
  position: relative;
  display: block;
  padding: 38px 40px 40px;
  background: #FFFFFF;
  box-shadow: 0px 4.4px 20px -1px rgba(19, 16, 34, 0.05);
  border-radius: 20px;
}

.sidebar__search-form {
  position: relative;
  display: block;
}

.sidebar__search-form input[type="search"] {
  display: block;
  outline: none;
  background-color: var(--onpoint-extra);
  border: none;
  font-weight: 400;
  height: 57px;
  width: 100%;
  padding-left: 20px;
  padding-right: 65px;
  color: var(--onpoint-gray);
  border-radius: 5px;
}

.sidebar__search-form button[type="submit"] {
  color: var(--onpoint-black);
  font-size: 16px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  max-width: 54px;
  width: 100%;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background-color: var(--onpoint-base);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.sidebar__search-form button[type="submit"]:hover {
  background-color: var(--onpoint-base);
  color: var(--onpoint-white);
}

.sidebar__post {
  position: relative;
  display: block;
  padding: 38px 40px 40px;
  background: #FFFFFF;
  box-shadow: 0px 4.4px 20px -1px rgba(19, 16, 34, 0.05);
  border-radius: 20px;
}

.sidebar__post-list {
  position: relative;
  display: block;
}

.sidebar__post-list li {
  position: relative;
  display: flex;
  align-items: center;
  background-color: var(--onpoint-extra);
  border-radius: 5px;
  padding-right: 20px;
}

.sidebar__post-list li+li {
  margin-top: 30px;
}

.sidebar__post-image {
  position: relative;
  display: block;
  max-width: 91px;
  width: 100%;
}

.sidebar__post-image img {
  width: 100%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.sidebar__post-content {
  position: relative;
  display: block;
  margin-left: 15px;
}

.sidebar__post-content h3 {
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  margin-top: 8px;
}

.sidebar__post-content h3>a {
  color: var(--onpoint-black);
}

.sidebar__post-content h3>a:hover {
  color: var(--onpoint-base);
}

.sidebar__post-date {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
}

.sidebar__post-date>i {
  font-size: 14px;
  color: var(--onpoint-primary);
  position: relative;
}

.sidebar__category {
  position: relative;
  display: block;
  padding: 38px 40px 40px;
  background: #FFFFFF;
  box-shadow: 0px 4.4px 20px -1px rgba(19, 16, 34, 0.05);
  border-radius: 20px;
}

.sidebar__category-list {
  position: relative;
  display: block;
}

.sidebar__category-list>li {
  position: relative;
  display: block;
}

.sidebar__category-list>li+li {
  margin-top: 20px;
}

.sidebar__category-list>li>a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--onpoint-extra);
  border-radius: 5px;
  color: var(--onpoint-gray);
  padding: 16px 45px 16px;
  padding-right: 20px;
  z-index: 1;
}

.sidebar__category-list>li>a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 5px;
  background-color: var(--onpoint-base);
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  transform-origin: bottom right;
  -webkit-transform: scale(1, 0);
  transform: scale(1, 0);
  z-index: -1;
}

.sidebar__category-list>li:hover>a::before {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  transform-origin: top center;
}

.sidebar__category-list>li>a:hover {
  color: var(--onpoint-white);
}

.sidebar__category-list>li>.icon {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  z-index: 2;
}

.sidebar__category-list>li>.icon>span {
  position: relative;
  display: inline-block;
  font-size: 11px;
  color: var(--onpoint-primary);
}

.sidebar__tags {
  position: relative;
  display: block;
  padding: 38px 40px 40px;
  background: #FFFFFF;
  box-shadow: 0px 4.4px 20px -1px rgba(19, 16, 34, 0.05);
  border-radius: 20px;
}

.sidebar__tags-list {
  margin-top: -15px;
  margin-left: -6px;
}

.sidebar__tags-list a {
  color: var(--onpoint-black);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  background-color: var(--onpoint-base);
  display: inline-block;
  padding: 5px 10px 6px;
  margin-left: 5px;
  border-radius: 5px;
}

.sidebar__tags-list a+a {
  margin-top: 15px;
}

.sidebar__tags-list a:hover {
  color: var(--onpoint-white);
  background-color: #000d51;
}

/*--------------------------------------------------------------
  # Project Details  
  --------------------------------------------------------------*/
.project-details {
  position: relative;
  display: block;
  padding: 120px 0 263px;
  z-index: 1;
}

.project-details__left {
  position: relative;
  display: block;
}

.project-details__information {
  position: relative;
  display: block;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px 6px rgba(0, 0, 0, 0.06);
  border-radius: 20px;
  padding: 38px 40px 40px;
  margin-bottom: 40px;
}

.project-details__information-title {
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
  margin-bottom: 28px;
}

.project-details__information-list {
  position: relative;
  display: block;
  background-color: var(--onpoint-extra);
  padding: 31px 30px 26px;
  border-radius: 20px;
}

.project-details__information-list li {
  position: relative;
  display: flex;
  align-items: center;
  gap: 30px;
}

.project-details__information-list li+li {
  margin-top: 21px;
}

.project-details__information-list li span {
  font-size: 15px;
  font-weight: 700;
  font-family: var(--onpoint-font-two);
  color: var(--onpoint-black);
  position: relative;
  max-width: 67px;
  width: 100%;
}

.project-details__information-list li p i {
  font-size: 15px;
  color: #E7E036;
}

.project-details__information-list li p .last-icon {
  color: #CCCCCC;
}

.project-details__information-list li p i+i {
  margin-left: 6px;
}

.project-details__social {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.project-details__social a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33px;
  height: 33px;
  font-size: 16px;
  color: var(--onpoint-black);
  border: 1px solid var(--onpoint-bdr-color);
  border-radius: 50%;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.project-details__social a:hover {
  border: 1px solid var(--onpoint-base);
  background-color: var(--onpoint-base);
  color: var(--onpoint-white);
}

.project-details__social a+a {
  margin-left: 10px;
}

.project-details__contact-list {
  position: relative;
  display: block;
}

.project-details__contact-list li {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid var(--onpoint-bdr-color);
  border-radius: 20px;
  gap: 20px;
  padding: 20px 30px 20px;
}

.project-details__contact-list li+li {
  margin-top: 20px;
}

.project-details__contact-list li .icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: rgba(var(--onpoint-base-rgb), .10);
}

.project-details__contact-list li .icon>span {
  position: relative;
  display: inline-block;
  font-size: 16px;
  color: var(--onpoint-base);
  transition: .2s;
  transform-origin: 50% 60%;
}

.project-details__contact-list li:hover .icon-1 {
  -webkit-animation: none;
  -webkit-transform: scale(1.4);
  animation: none;
  transform: scale(1.4);
}

.project-details__contact-list li:hover .icon-2 {
  -webkit-animation: wiggle .05s alternate ease infinite;
  animation: wiggle .05s alternate ease infinite;
}

.project-details__contact-list li:hover .icon-3 {
  -webkit-animation: none;
  -webkit-transform: skew(-20deg);
  animation: none;
  transform: skew(-20deg);
  text-indent: .1em;
}

.project-details__contact-list li .content {
  position: relative;
  display: block;
}

.project-details__contact-list li .content h3 {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  margin-bottom: 5px;
}

.project-details__contact-list li .content p {
  color: var(--onpoint-gray);
}

.project-details__contact-list li .content p>a {
  color: var(--onpoint-gray);
}

.project-details__contact-list li .content p>a:hover {
  color: var(--onpoint-base);
}

.project-details__right {
  position: relative;
  display: block;
}

.project-details__img {
  position: relative;
  display: block;
}

.project-details__img img {
  width: 100%;
  border-radius: 20px;
}

.project-details__title-1 {
  font-size: 48px;
  font-weight: 700;
  line-height: 58px;
  margin-top: 26px;
  margin-bottom: 15px;
}

.project-details__text-2 {
  margin-top: 31px;
  margin-bottom: 30px;
}

.project-details__points {
  position: relative;
  display: block;
}

.project-details__points li {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
}

.project-details__points li+li {
  margin-top: 21px;
}

.project-details__points li .icon {
  position: relative;
  display: inline-block;
}

.project-details__points li .icon span {
  position: relative;
  display: inline-block;
  font-size: 11px;
  color: var(--onpoint-base);
}

.project-details__img-box {
  position: relative;
  display: block;
  margin-top: 31px;
}

.project-details__img-box-img {
  position: relative;
  display: block;
  margin-bottom: 30px;
}

.project-details__img-box-img img {
  width: 100%;
  border-radius: 20px;
}

/*--------------------------------------------------------------
  # About Two
  --------------------------------------------------------------*/
.about-two {
  padding-top: 120px;
  /* background-color: #f6f7f9; */
}

/*--------------------------------------------------------------
  # CTA Two
  --------------------------------------------------------------*/
.cta-two {
  margin-top: 0;
}

/*--------------------------------------------------------------
  # Services Page
  --------------------------------------------------------------*/
.services-page {
  position: relative;
  display: block;
  padding: 160px 0 50px;
}

/* write */

.banner-text-color {
  color: #f39f2f;
}

/* .ft-about-section-3 {
    padding: 115px 0px;
  } */

.ft-section-title-3 span {
  color: #f39f2f;
  font-size: 18px;
  font-weight: 500;
}

.ft-section-title-3 h2 {
  color: #141415;
  font-size: 48px;
  font-weight: 700;
  padding-top: 12px;
}

.ft-about-text-3 .ft-about-sub-text {
  color: #272727;
  font-size: 17px;
  line-height: 1.765;
  padding: 25px 0px 20px;
}

/* membership-benefits */
.ft-service-content-3 {
  padding-top: 40px;
}

.ft-service-content-items-3 {
  overflow: hidden;
  margin-bottom: -10px;
  padding-bottom: 10px;
}

.position-relative {
  position: relative !important;

}

.ft-service-innerbox-3 .ft-service-img {
  overflow: hidden;
}

.ft-service-innerbox-3 .ft-service-text-icon {
  top: -70px;
  max-width: 83%;
  margin: 0 auto 0 15px;
  /* margin-bottom: -70px; */
  padding: 35px 30px 75px;
  -webkit-transition: 500ms all ease;
  transition: 500ms all ease;
  background-color: white;
  -webkit-box-shadow: 0px 0px 29px 0px rgba(12, 12, 12, 0.07);
  box-shadow: 0px 0px 29px 0px rgba(12, 12, 12, 0.07);
}

.ft-service-innerbox-3 .ft-service-text-icon:before {
  left: 1px;
  top: -65px;
  content: "";
  position: absolute;
  -webkit-transition: 500ms all ease;
  transition: 500ms all ease;
  border-bottom: 70px solid #fff;
  border-left: 292px solid rgba(255, 255, 255, 0);
}

.ft-service-innerbox-3 .ft-service-icon {
  right: 30px;
  top: -100px;
  z-index: 1;
  width: 95px;
  height: 95px;
  border-radius: 100%;
  background-color: #f39f2f;
  border: 10px solid #244770;
  -webkit-transition: 500ms all ease;
  transition: 500ms all ease;

}

.ft-service-innerbox-3 .ft-service-icon i {
  color: #141415;
  font-size: 40px;
  line-height: 0.75;
}

.ft-service-innerbox-3 .ft-service-text-icon:after {
  top: -36px;
  left: -7px;
  width: 103%;
  content: "";
  height: 15px;
  position: absolute;
  background-color: #244770;
  -webkit-transform: rotate(-12deg) skew(-15deg);
  transform: rotate(-12deg) skew(-15deg);
}

.ft-service-innerbox-3 .ft-service-text h3 {
  color: #121213;
  font-size: 22px;
  font-weight: 700;
  padding-bottom: 15px;
}

.ft-service-innerbox-3 .ft-service-text p {
  color: #121213;
  -webkit-transition: 500ms all ease;
  transition: 500ms all ease;
}

.ft-service-innerbox-3 .more-btn {
  right: 61px;
  bottom: 70px;
}

.position-absolute {
  position: absolute !important;
}

.ft-service-innerbox-3 .more-btn a {
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  position: relative;
  padding: 14px 26px;
  background-color: #008eff;
}

.justify-content-center {
  justify-content: center !important;
}

.ft-service-innerbox-3:hover .ft-service-text-icon {
  background-color: #01011c;
}

.ft-service-innerbox-3:hover .ft-service-text-icon:before {
  border-bottom: 70px solid #01011c;
}

.ft-service-innerbox-3:hover .ft-service-icon {
  border: 10px solid var(--onpoint-base);
}

.ft-service-innerbox-3:hover .ft-service-text h3,
.ft-service-innerbox-3:hover .ft-service-text p {
  color: #fff;
}

.pera-content p {
  margin-bottom: 0;
}

.ft-service-innerbox-3 .more-btn a:hover {
  background-color: var(--onpoint-base);
}

.ft-service-innerbox-3 .more-btn a:hover:before {
  border-bottom: 54px solid var(--onpoint-base);
  ;
}

.ft-service-innerbox-3 .more-btn a:before {
  top: 0;
  width: 0;
  height: 0;
  content: "";
  left: -16px;
  position: absolute;
  -webkit-transition: 500ms all ease;
  transition: 500ms all ease;
  border-bottom: 54px solid #008eff;
  border-left: 15px solid transparent;
}

.police-heading {
  margin: 10px 0;
  font-weight: 700;
}

.police-paragraph {
  margin: 5px 0;
}

.cookie-text {
  color: #121213;
}

.policy-privacy {
  padding: 50px 0 160px 0;
}

ol li {
  padding: 10px 7px;
}

.pr-ul .icon i {
  width: 40px;
  height: 40px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 2px solid #EEEEEE;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  /* margin-right: 20px; */
  color: #f39f2f;
}

.pr-ul li {
  padding: 10px 7px;
  list-style: none;
}

.text-color {
  color: #f39f2f;
  font-size: 21px;
  font-weight: 700;
  font-family: sans-serif;
}

.text-color-heading {
  color: #f39f2f;
  font-size: 2.3rem;
}

.ft-contact-section {
  z-index: 1;
  overflow: hidden;
  padding: 105px 0px 140px;
}

[data-background] {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

@media screen and (max-width: 1650px) {
  .ft-contact-section:before {
    left: -30%;
  }
}

.ft-contact-section:before {
  top: 0;
  width: 70%;
  z-index: -1;
  content: "";
  left: -22%;
  height: 100%;
  position: absolute;
  -webkit-transform: skew(-43deg);
  transform: skew(-43deg);
  background-color: #f4f4f4;
}

.ft-contact-content {
  max-width: 580px;
}

.ft-section-title .sub-title {
  font-size: 20px;
  font-weight: 700;
  color: #f39f2f;
  padding: 0px 82px;
  position: relative;
}

.ft-section-title .sub-title:before {
  left: 0;
}

.ft-section-title h2 {
  color: #00044b;
  font-size: 36px;
  font-weight: 700;
  line-height: 1.333;
  padding: 10px 0px 20px;
}

.ft-contact-form-wrapper {
  margin-top: 30px;
}

.ft-contact-form-wrapper input,
.ft-contact-form-wrapper textarea {
  width: 100%;
  height: 55px;
  border-radius: 5px;
  margin-bottom: 20px;
  padding-left: 20px;
  border: 1px solid #cbcbcb;
}

.ft-contact-form-wrapper .ft-sb-button {
  background-color: #00044b;
}

.ft-sb-button:after {
  content: "";
  position: absolute;
  top: 0;
  left: -200%;
  width: 200%;
  height: 100%;
  -webkit-transform: skewX(-20deg);
  -khtml-transform: skewX(-20deg);
  transform: skewX(-20deg);
  background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(rgba(255, 255, 255, 0.4)), to(transparent));
  background-image: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.4), transparent);
  z-index: -1;
}

.ft-sb-button {
  color: #fff;
  height: 55px;
  width: 170px;
  border: none;
  font-weight: 600;
  border-radius: 6px;
  background-color: #f39f2f;
}

.ft-about-section-3,
.ft-service-section-3 {
  padding: 100px 100px;
}

/* 1wn vertical */

.team-area {
  background: url(../../public/images/backgrounds/vertical-bg.jpg) center center / cover no-repeat scroll;
}

.vertical-bg {
  background-color: rgba(0, 0, 0, 0.8);
  padding-top: 85px;
  padding-bottom: 45px;
}

.dreamit-section-title h4 {
  font-size: 16px;
  font-weight: 600;
  color: #f29f2d;
  position: relative;
  display: inline-block;
  margin-bottom: 5px;
  padding: 0px;
  letter-spacing: 1px;
  z-index: 1;
  margin-left: 75px;
}

.bg-bar2.dreamit-section-title h4::before {
  position: absolute;
  content: "";
  right: -72px;
  top: 7px;
  height: 4px;
  width: 55px;
  background: rgb(242, 159, 45);
  border-width: 4px 4px 0px;
  border-top-style: solid;
  border-right-style: solid;
  border-left-style: solid;
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
  border-image: initial;
  border-bottom-style: initial;
  border-bottom-color: initial;
  border-radius: 0px 0px 15px 15px;
}

.bg-bar2.dreamit-section-title h4::after {
  position: absolute;
  content: "";
  left: -72px;
  top: 7px;
  height: 4px;
  width: 55px;
  background: rgb(242, 159, 45);
  border-width: 4px 4px 0px;
  border-top-style: solid;
  border-right-style: solid;
  border-left-style: solid;
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
  border-image: initial;
  border-bottom-style: initial;
  border-bottom-color: initial;
  border-radius: 0px 0px 15px 15px;
}

.border-center span::after {
  position: absolute;
  content: "";
  left: -72px;
  top: 7px;
  height: 4px;
  width: 55px;
  background: rgb(242, 159, 45);
  border-width: 4px 4px 0px;
  border-top-style: solid;
  border-right-style: solid;
  border-left-style: solid;
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
  border-image: initial;
  border-bottom-style: initial;
  border-bottom-color: initial;
  border-radius: 0px 0px 15px 15px;
}

.border-center span::before {
  position: absolute;
  content: "";
  right: -72px;
  top: 7px;
  height: 4px;
  width: 55px;
  background: rgb(242, 159, 45);
  border-width: 4px 4px 0px;
  border-top-style: solid;
  border-right-style: solid;
  border-left-style: solid;
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
  border-image: initial;
  border-bottom-style: initial;
  border-bottom-color: initial;
  border-radius: 0px 0px 15px 15px;
}

.dreamit-section-title h3 {
  font-size: 38px;
  font-weight: 800;
  margin-top: 0px;
  line-height: 1.2;
}

.dreamit-team-single-box {
  margin-bottom: 30px;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fadeInLeft {
  --webkit-animation-name: fadeInLeft;
  /* animation-name: fadeInLeft; */
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.style-two .dreamit-team-thumb {
  position: relative;
}

.dreamit-team-thumb {
  overflow: hidden;
}

.style-two .dreamit-team-thumb::before {
  position: absolute;
  content: "";
  inset: 0px;
  height: 100%;
  width: 100%;
  background: rgba(242, 159, 45, 0.8);
  border-radius: 50%;
  z-index: 1;
  transform: scale(0);
  transition: all 0.5s ease 0s;
}

.style-two .team-content-socials {
  position: relative;
  text-align: center;
  margin-top: -33px;
}

.style-two .team-content-socails-inner {
  background: rgb(37, 70, 113);
  display: inline-block;
  padding: 7px 17px;
  position: inherit;
  opacity: 1;
  top: inherit;
  left: inherit;
  z-index: 1;
  margin-left: 50px;
  width: 110px;
}

.team-content-socails-inner {
  background: rgba(0, 0, 0, 0.7);
  display: inline-block;
  padding: 25px 11px 44px;
  position: absolute;
  right: 26px;
  top: -90px;
  border-radius: 30px;
  opacity: 0;
  transition: all 0.3s ease 0s;
}

.style-two .team-content-socails-inner a {
  font-size: 16px;
  color: rgb(255, 255, 255);
  display: inline-block;
  text-align: center;
  transition: all 0.3s ease 0s;
  margin-left: 10px;
}

.style-two .dreamit-team-thumb img {
  width: 100%;
  border-radius: 100%;
  /* box-shadow: rgb(204, 204, 204) 0px 0px 10px; */
  /* border: 8px solid rgb(255, 255, 255); */
  transform: scale(1);
  padding: 20px;
  background-color: #fff;
}

.dreamit-team-thumb img {
  width: 100%;
  transform: scale(1);
  transition: all 0.5s ease 0s;
}

.style-two .dreamit-team-thumb:hover::before {
  transform: scale(1);
}

.vertival-div {
  display: flex;
  justify-content: center;
}

.gl-testimonial-map {
  left: 205px;
  top: 157px;
  z-index: -1;
}



h1 {
  font-size: 28px;
}

h2 {
  font-size: 23.8px;
}

h3 {
  font-size: 18.2px;
}

.btn-primary {
  background: #f39f2f;
  color: #fff;
}

.btn-primary:hover,
.btn-primary:focus {
  background: #f39f2f;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -obox-shadow: none;
  box-shadow: none;
  border-color: #f39f2f;
}

.btn-default {
  background: #254671;
  color: #fff;
}

.btn-default:hover {
  background: #254671;
  color: #fff;
}

input[type=text],
input[type=email],
input[type=tel],
input[type=password],
textarea,
select {
  width: 100%;
  display: block;
  padding: 12px 15px;
  /* border: 1px solid #ccc; */
  background: #f9f9f9 !important;
}

.css-hcdzbm-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
  background: #f9f9f9 !important;
}

.mar-b-0 {
  margin-bottom: 0 !important;
}

a {
  color: #1754d1;
}

a:hover {
  color: #f39f2f;
}

.form-wizard {
  position: relative;
  display: table;
  margin: 0 auto 188px;
  max-width: 540px;
}

.steps {
  margin: 40px 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.steps ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.steps ul li {
  float: left;
  color: #254671;
  padding: 0 15px;
  position: relative;
  cursor: pointer;
  -webkit-transition: all 0.4s ease-in-out 0;
  -moz-transition: all 0.4s ease-in-out 0;
  -ms-transition: all 0.4s ease-in-out 0;
  -otransition: all 0.4s ease-in-out 0;
  transition: all 0.4s ease-in-out 0;
}

.steps ul li:hover,
.steps ul li.active {
  color: #f39f2f;
}

.steps ul li:hover span,
.steps ul li.active span {
  background: #f39f2f;
  color: #fff;
}

.steps ul li:hover::after,
.steps ul li.active::after {
  background: #f39f2f;
  width: 100%;
}

.steps ul li::before,
.steps ul li::after {
  content: "";
  position: absolute;
  left: -50%;
  top: 22px;
  width: 100%;
  height: 3px;
  background: #254671;
  -webkit-transition: all 0.4s ease-in-out 0;
  -moz-transition: all 0.4s ease-in-out 0;
  -ms-transition: all 0.4s ease-in-out 0;
  -otransition: all 0.4s ease-in-out 0;
  transition: all 0.4s ease-in-out 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -otransform: translateY(-50%);
  transform: translateY(-50%);
}

.steps ul li::after {
  width: 0;
}

.steps ul li span {
  display: block;
  margin: 0 auto 15px;
  width: 35px;
  height: 35px;
  text-align: center;
  background: #254671;
  font-size: 18px;
  line-height: 35px;
  font-weight: 300;
  color: #ffffff;
  position: relative;
  z-index: 1;
  -webkit-transition: all 0.4s ease-in-out 0;
  -moz-transition: all 0.4s ease-in-out 0;
  -ms-transition: all 0.4s ease-in-out 0;
  -otransition: all 0.4s ease-in-out 0;
  transition: all 0.4s ease-in-out 0;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -oborder-radius: 2px;
  border-radius: 2px;
}

.steps ul li:first-child::before,
.steps ul li:first-child::after {
  display: none;
}

.form-container {
  clear: both;
  display: none;
  left: 100%;
  background: #fff;
  padding: 30px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -oborder-radius: 4px;
  border-radius: 4px;
}

.form-container.active {
  display: block;
}

.form-title {
  margin-bottom: 30px;
  padding-bottom: 15px;
  position: relative;
}

.form-title::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 80px;
  height: 2px;
  background: #f39f2f;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -otransform: translateX(-50%);
  transform: translateX(-50%);
}

.form-check-input[type=radio] {
  margin-bottom: 30px;
}

:focus-visible {
  outline: none !important;
}






.icon .fa-handshake {
  font-size: 63px;
  color: #ffffff;
  font-weight: 100;
}

.icon-postbox:before {
  content: "\e921";
}


.icon-airplane:before {
  content: "\e900";
}

.icon-angle-left:before {
  content: "\e901";
}

.icon-angle-right:before {
  content: "\e902";
}

.icon-arrow-down-left:before {
  content: "\e903";
}

.icon-arrow-down-right:before {
  content: "\e904";
}

.icon-arrow-left:before {
  content: "\e905";
}

.icon-arrow-right:before {
  content: "\e906";
}

.icon-calender:before {
  content: "\e907";
}

.icon-call:before {
  content: "\e908";
}

.icon-chat-1:before {
  content: "\e909";
}

.icon-clock:before {
  content: "\e90a";
}

.icon-comments:before {
  content: "\e90b";
}

.icon-comments-2:before {
  content: "\e90c";
}

.icon-container:before {
  content: "\e90d";
}

.icon-conveyor-1:before {
  content: "\e90e";
}

.icon-crane:before {
  content: "\e90f";
}

.icon-customer-service:before {
  content: "\e910";
}

.icon-dabble-arrow:before {
  content: "\e911";
}

.icon-down-arrow:before {
  content: "\e912";
}

.icon-envolop:before {
  content: "\e913";
}

.icon-facebook-f:before {
  content: "\e914";
}

.icon-fragile:before {
  content: "\e915";
}

.icon-instagram:before {
  content: "\e916";
}

.icon-ionic-ios-people:before {
  content: "\e917";
}

.icon-lift-truck:before {
  content: "\e918";
}

.icon-linkedin-in:before {
  content: "\e919";
}

.icon-location:before {
  content: "\e91a";
}

.icon-menu:before {
  content: "\e91b";
}

.icon-minus:before {
  content: "\e91c";
}

.icon-paper-plane:before {
  content: "\e91d";
}

.icon-phone:before {
  content: "\e91e";
}

.icon-play:before {
  content: "\e91f";
}

.icon-plus:before {
  content: "\e920";
}

.icon-postbox:before {
  content: "\e921";
}

.icon-quote:before {
  content: "\e922";
}

.icon-schedule:before {
  content: "\e91a";
}

.icon-search:before {
  content: "\e924";
}

.icon-shipping:before {
  content: "\e925";
}

.icon-shopping-cart:before {
  content: "\e926";
}

.icon-star:before {
  content: "\e927";
}

.icon-user:before {
  content: "\e928";
}

.icon-user-2:before {
  content: "\e929";
}

.icon-Vector:before {
  content: "\e92a";
}

/* team-section */

.arora-padding {
  padding: 0px 0;
}

.ft-team-details-section {
  padding: 140px 0px;
  background-image: url(../../public/images/resources/sunil-bg.png );
  background-position: 70% 60%;
}

.ft-team-details-content {
  padding: 0px 65px;
  border-radius: 8px;
  /* margin: 65px 0px; */
  /* background-color: #003440; */
}

.position-relative {
  position: relative !important;
}

.design-shape {
  bottom: -140px;
  right: 0;
}

.ft-team-details-content .ft-team-details-img {
  overflow: hidden;
  max-width: 365px;
  border-radius: 8px;
  z-index: 2;
}

/* 
.ft-team-details-content .ft-team-details-text-wrapper {
  padding: 50px 0px;
} */

/* .ft-team-details-content .ft-team-details-text {
  color: #000000;
  padding-bottom: 22px;
} */

.ft-team-details-content .ft-team-details-text h3 {
  color: #000000;
  font-size: 36px;
  font-weight: 700;
  padding-bottom: 10px;
}

.ft-team-details-content .ft-team-details-text span {
  font-size: 18px;
  font-weight: 700;
  display: inline-block;
  padding-bottom: 10px;
}

.ft-team-details-content .ft-team-details-text p {
  text-align: justify;
  width: 90%;
}

.ceo-color {
  color: #f39f2f;
  font-size: 35px !important;
  letter-spacing: 3px;
}

.peragraph-sunil {
  position: relative;
  z-index: 10;
}

.text-color {
  color: #000000;
}

/* fixed button */
#fixed-btn {
  bottom: 20%;
  right: -162px;
  position: fixed;
  z-index: 500;
}

.catalog-res .catalog-btn {
  background-image: url(../../public/images/icon/join-us.png);
  height: 39px;
  display: block;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  background-position: 3% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  transform: translate(-6px);
  position: relative;
  transition: transform ease-in-out .6s;
  white-space: nowrap;
  border: 0;
  margin-bottom: 8px;
}

.catalog-res .catalog-btn:hover {
  background-image: none;
}

.catalog-res .catalog-btn:hover {
  transform: translate(-77%);
  text-decoration: none;
}

.catalog-res .catalog-text {
  display: block;
  background: #f39f2f;
  color: #fff;
  height: 38px;
  margin-left: 100px;
  padding: 0 20px 0 15px;
  line-height: 39px;
  width: 130px;
}

.whatsapp-icon-img img {
  height: 43px;
}

.whatsappWidth {
  max-width: 100%;
}

.padding-section {
  padding-bottom: 17% !important;
  padding-top: 40px;
}

.contact-benefit-overlay {
  padding-bottom: 7%;
}

/* eventConfrences */


.blog-details-img-text-wrapper .ft-blog-details-section {
  padding: 100px 0px;
}

.blog-details-img-text-wrapper .ft-blog-details-item {
  padding: 20px 30px;
  background-color: #f4f4f4;
}

.blog-details-img-text-wrapper .ftd-blog-meta-2 {
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e4ebf2;
}

.blog-details-img-text-wrapper .ftd-blog-meta-2:after {
  bottom: 0;
  left: 0;
  height: 3px;
  width: 40px;
  content: "";
  position: absolute;
  background-color: #f39f2f;
}

.ftd-blog-meta-2 a {
  margin-right: 25px;
  position: relative;
  color: #f39f2f;
}

.blog-details-img-text-wrapper .ftd-blog-meta-2 a i {
  font-size: 18px;
  margin-right: 5px;
  color: #f39f2f;
}

.blog-details-img-text-wrapper .blog-details-text article {
  margin-bottom: 25px;
}

.eventpagepadding {
  padding: 100px;
}

label {
  display: block;
  /* Display labels on separate lines */
  margin-bottom: 8px;
  /* Space between each label */
}

input[type="radio"] {
  margin-right: 5px;
  /* Space between radio button and label text */
}

.ft-contact-form-wrapper input[type=radio] {
  height: 20px;
  width: 17px;
}

.radio-btn {
  display: flex;
}

.radio-inline {
  display: flex;
  margin: 8px;
}

.form-switch .form-check-input {
  width: 12%;
  height: 30px;
}

.form-switch {
  padding-left: 0px;
}

.form-switch .form-check-input {
  margin-left: 0.5rem;
}

.btn-lg {
  font-size: 2rem;
}

.artical-text {
  text-align: justify;
}

input[type="file"]::-webkit-file-upload-button {
  height: 53px;
  border: none !important;
  padding-left: 0px !important;
}

.ft-contact-form-wrapper input[type=file] {
  padding-left: 0px !important;
}

input[type="file"] {
  height: 43px !important;
}

.css-hcdzbm-MuiAutocomplete-root .MuiAutocomplete-input {
  height: 43px !important;
}


/* Stepper Form */

.css-v3cfyk-MuiStepper-root {
  margin: auto;
}

.css-qwse39-MuiGrid-root {
  justify-content: end !important;
  margin-top: 20px;
  max-width: 100%;
  margin: 20px 64px 10px;
  /* position: relative; */
}
.btn-member{
  max-width: 87%;
}
.grid-width-block, .member-block-body{
  max-width: 600px;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
  width: 35px !important;
  height: 35px !important;
  border-radius: 2px;
  fill: #254671 !important;
}

/* .css-vnkopk-MuiStepLabel-iconContainer{
  position: absolute;
  top: -30px;
  z-index: 4;
} */

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active,
.css-1hv8oq8-MuiStepLabel-label.Mui-active {
  fill: #f39f2f !important;
}

.css-1mzvc25-MuiStepLabel-root .MuiStepLabel-label {
  text-transform: inherit !important;
  font-size: 15px !important;
  font-weight: 500;
}

.css-1mzvc25-MuiStepLabel-root {
  flex-direction: column;
}

.css-1vyamtt-MuiStepLabel-labelContainer {
  margin-top: 10px;
  text-align: center;
}

.css-1mzvc25-MuiStepLabel-root .MuiStepLabel-label {
  color: #254671 !important;
}

.css-1hv8oq8-MuiStepLabel-label.Mui-active {
  color: #f39f2f !important;
}

.css-v3cfyk-MuiStepper-root::before {
  content: '';
  height: 3px;
  width: 100px;
  background-color: #254671;
  position: absolute;
  margin-left: 80px;
  z-index: 0;
  top: 18px;
  width: 84%;
}

.css-v3cfyk-MuiStepper-root {
  position: relative !important;
  margin-bottom: 30px !important;
}

.css-z7uhs0-MuiStepConnector-line {
  border-color: #ffffff !important;
}

/* .css-1bw0nnu-MuiStep-root {
  margin-left: 23px;
} */

.css-j5w0w9-MuiStepConnector-root {
  flex: auto !important;
}

.css-1ps6pg7-MuiPaper-root {
  box-shadow: none !important;
  background-color: transparent !important;
  width: 100%;
}
#membership .css-z1o969-MuiInputBase-root-MuiOutlinedInput-root{
  background-color: #f9f9f9 !important;
}

.css-zcbmsk-MuiButtonBase-root-MuiButton-root {
  width: 100%;
  margin-top: 15px !important;
  padding: 12px !important;
}

.css-zcbmsk-MuiButtonBase-root-MuiButton-root.Mui-disabled {
  color: rgb(5 4 4 / 56%) !important;
  box-shadow: none;
  background-color: rgb(0 0 0 / 10%);
  font-weight: 700;
}

.css-zcbmsk-MuiButtonBase-root-MuiButton-root {
  background-color: #f39f2f !important;
  box-shadow: none !important;
}

.heading {
  display: flex;
  justify-content: center;
}

.css-3p97a2-MuiTypography-root {
  border-image:
    linear-gradient(to bottom,
      red,
      rgba(0, 0, 0, 0)) 1 100%;
  margin-bottom: 45px !important;
  margin-top: 20px;
}

/* .css-sghohy-MuiButtonBase-root-MuiButton-root{

} */

.css-1ygcj2i-MuiTableCell-root,
.css-1azl6jz-MuiTableCell-root {
  color: #000000 !important;
  font-weight: 500 !important;
}

@media screen and (max-width: 1400px) {
  .css-1mzvc25-MuiStepLabel-root .MuiStepLabel-label {
    text-transform: inherit !important;
    font-size: 13px !important;
    font-weight: 500;
    text-align: center;
  }
}

@media screen and (max-width: 1100px) {
  .css-v3cfyk-MuiStepper-root::before {
    width: 82%;
    margin-left: 60px;
  }
}

@media screen and (max-width: 893px) {
  .css-v3cfyk-MuiStepper-root::before {
    background-color: #fff;
  }

  .css-v3cfyk-MuiStepper-root {
    flex-wrap: wrap;
  }

  .css-1bw0nnu-MuiStep-root {
    margin-left: 23px;
    width: 29%;
    margin-bottom: 21px;
    padding: 15px 10px;
    border: 1px solid #bebfc0;
  }
}

@media screen and (max-width: 768px) {
  .css-qwse39-MuiGrid-root {
    max-width: 100%;
  }

  .css-1bw0nnu-MuiStep-root {
    width: 45%;
  }

  .css-gdvilq {
    width: 100% !important;
  }
}


.btn.btn-submit,
.btn.btn-submit:hover {
  background-color: #244770;
  color: #ffffff;
  font-size: 14px;
  padding: 8px 26px;
}

.btn.btn-back,
.btn.btn-back:hover {
  background-color: #f39f2f;
  color: #244770;
  /* font-size: 15px; */
  padding: 5px 26px;
}

.banner-one__img {
  max-width: 50%;
}

.form-checkbox input[type=checkbox] {
  height: 15px;
  width: 6%;
}

.registration-heading {
  position: relative;
  top: 62px;
  z-index: 3;
  text-align: center;
  font-weight: 500;
  font-size: 33px;
  color: #f39f2f;
}

/* Vertical one */

.vertical {
  padding: 95px 0px;
  overflow: hidden;
  background-color: #f5f8fd;
}

.vertical .vertical-title p {
  padding-bottom: 15px;
}

.vertical-title .vertical-tag {
  font-size: 18px;
  font-weight: 700;
  color: #000d51;
}

.vertical-title .vertical-tag:after {
  top: 15px;
  height: 2px;
  width: 35px;
  content: "";
  right: -40px;
  position: absolute;
  background-color: #000d51;
}

.vertical-title .vertical-tag {
  font-size: 18px;
  font-weight: 700;
  color: #000d51;
}

.vertical-title h2 span {
  color: #000d51;
}

.vertical-title h2 {
  font-size: 48px;
  font-weight: 700;
  color: #f39f2f;
  padding: 20px 0px;
}

.vertical .vertical-title p {
  padding-bottom: 15px;
}

.vertical-list {
  padding: 20px 0px;
}

.ul-li-block ul {
  margin: 0;
  padding: 0;
}

.ul-li-block ul li {
  display: block;
  list-style: none;
}

.vertical-list li {
  padding-left: 55px;
  margin-bottom: 18px;
  position: relative;
}

.vertical-list li i {
  top: 0;
  left: 0;
  font-size: 25px;
  font-weight: 900;
  color: #f39f2f;
  position: absolute;
  /* font-family: "Font Awesome 5 Pro"; */
}

.vertical-img-area {
  background-color: #254671;
  width: 684px;
  height: 558px;
}

.text-color-content {
  color: #222;
  font-weight: 500;
}

.ft-about-shape1 {
  right: 0;
  top: -1px;
  position: absolute;
  width: 170px;
}

/* .pr-sx-about-exp {
  top: 55px;
  right: -120px;
  width: 260px;
  height: 105px;
  padding-left: 15px;
  border-radius: 8px;
  position: absolute;
  background-color: #fff;
  -webkit-box-shadow: 13.435px 13.435px 30px 0px rgba(55, 54, 54, 0.2);
  box-shadow: 13.435px 13.435px 30px 0px rgba(55, 54, 54, 0.2);
} */

/* Vertical one */

/* vertical section */

.ft3-services-section-four {
  position: relative;
  overflow: visible;
  z-index: 10;
  padding: 100px 0px 200px;
}

.ft3-services-section-four .ft3-service-block-three {
  padding: 0px 0px;
  float: left;
}

.ft3-service-block-three {
  position: relative;
  margin-bottom: 40px;
}

.ft3-service-block-three .inner-box {
  position: relative;
  padding: 275px 50px;
}

.ft3-service-block-three .inner-box h5 {
  position: relative;
  padding-bottom: 15px;
}

.ft3-service-block-three .inner-box h5:before {
  position: absolute;
  content: "";
  left: 0px;
  bottom: 0px;
  height: 3px;
  width: 40px;
  /* background-color: #dd2602; */
}

.ft3-service-block-three .inner-box .text {
  position: relative;
  color: #454545;
  font-size: 16px;
  line-height: 1.6em;
  margin-top: 30px;
}

.ft3-service-block-three .inner-box .overlay-box .shape-layer {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  height: 180px;
  -webkit-clip-path: polygon(0 0, 100% 0, 75% 100%, 25% 100%);
  clip-path: polygon(0 0, 100% 0, 75% 100%, 25% 100%);
}

.ft3-service-block-three .inner-box .overlay-box {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  text-align: center;
  -webkit-transition: all 900ms ease;
  transition: all 900ms ease;
  -webkit-transform: perspective(400px) rotateX(-90deg);
  transform: perspective(400px) rotateX(-90deg);
  -webkit-transform-origin: top;
  transform-origin: top;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.ft3-service-block-three .overlay-box .overlay-image-layer {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  height: 100%;
}

.bg-global {
  background-color: #948ebe40;
}

.bg-global-shap {
  background-color: #948ebe;
}

.bg-personal {
  background-color: #b4b52940;
}

.bg-personal-shap {
  background-color: #b4b529;
}

.bg-express {
  background-color: #01d0b440;
}

.bg-express-shap {
  background-color: #01d0b4;
}

.bg-dangerous {
  background-color: #dd293040;
}

.bg-dangerous-shap {
  background-color: #dd2930;
}

.bg-project {
  background-color: #03a63a40;
}

.bg-project-shap {
  background-color: #03a63a;
}

.bg-temp {
  background-color: #f052a640;
}

.bg-temp-shap {
  background-color: #f052a6;
}

.ft3-service-block-three.active .inner-box .overlay-box,
.ft3-service-block-three .inner-box:hover .overlay-box {
  -webkit-transform: perspective(400px) rotateX(0deg);
  transform: perspective(400px) rotateX(0deg);
}

.ft3-service-block-three .overlay-inner {
  display: flex;
  padding: 10px 30px;
}

.ft3-service-block-three .overlay-inner .content {
  position: relative;
  display: table-cell;
  vertical-align: middle;
}

.icon-box {
  margin: auto 121px;
  padding-top: 20px;
}

.ft3-service-block-three .overlay-inner h5 {
  position: relative;
  padding-bottom: 15px;
  margin-top: 62px;
  font-size: 20px;
  color: #222222;
}

.ft3-service-block-three .overlay-inner h5:before {
  position: absolute;
  content: "";
  left: 50%;
  bottom: 0px;
  height: 3px;
  width: 34px;
  margin-left: -17px;
  background-color: #969595;
}

.ft3-service-block-three .overlay-inner .lower-text {
  position: relative;
  font-size: 16.5px;
  line-height: 1.6em;
  margin-top: 20px;
  text-align: justify;
  color: #222;
}

/* vertical section */


/* team */

.pb-100 {
  padding-bottom: 245px;
}

/* .pt-100 {
  padding-top: 120px;
} */

.mb-50 {
  margin-bottom: 50px;
}

.st__section__title.text-center h5 {
  margin-left: 13px;
}

.st__section__title.style2 h5 {
  color: #003d4d;
}

.st__section__title h5 {
  color: #f39f2f;
  position: relative;
  display: inline-block;
  margin-left: 46px;
  font-size: 24px;
  font-weight: 500;
}

.st__section__title h5::before {
  position: absolute;
  content: "";
  top: 0;
  left: -45px;
  background: #f39f2f;
  height: 16px;
  width: 16px;
  transform: rotate(45deg);
  transition: all 1000ms ease;
  -webkit-animation: st__rotate 10s infinite linear;
  -moz-animation: st__rotate 5s infinite linear;
  -o-animation: st__rotate 5s infinite linear;
  animation: st__rotate 5s infinite linear;
  -webkit-animation-play-state: running;
  -moz-animation-play-state: running;
  -o-animation-play-state: running;
  animation-play-state: running;
  z-index: 1;
}

.st__section__title h5::after {
  position: absolute;
  content: "";
  top: 0;
  left: -39px;
  background: #003d4d;
  height: 16px;
  width: 16px;
  transform: rotate(45deg);
  transition: all 1000ms ease;
  -webkit-animation: st__rotate 10s infinite linear;
  -moz-animation: st__rotate 5s infinite linear;
  -o-animation: st__rotate 5s infinite linear;
  animation: st__rotate 5s infinite linear;
  -webkit-animation-play-state: running;
  -moz-animation-play-state: running;
  -o-animation-play-state: running;
  animation-play-state: running;
  z-index: 1;
}

.circle {
  display: inline-block;
  position: relative;
}

.st__section__title h1 {
  font-size: 48px;
  line-height: 52px;
  color: #003d4d;
  font-weight: 600;
  margin-top: 22px;
}

.circle::before {
  position: absolute;
  content: "";
  top: -20px;
  left: 20px;
  background: #f39f2f;
  height: 16px;
  width: 16px;
  transform: rotate(45deg);
  transition: all 1000ms ease;
  -webkit-animation: st__rotate 10s infinite linear;
  -moz-animation: st__rotate 5s infinite linear;
  -o-animation: st__rotate 5s infinite linear;
  animation: st__rotate 5s infinite linear;
  -webkit-animation-play-state: running;
  -moz-animation-play-state: running;
  -o-animation-play-state: running;
  animation-play-state: running;
  z-index: 1;
}

.circle::after {
  position: absolute;
  content: "";
  top: -16px;
  left: 20px;
  background: #003d4d;
  height: 16px;
  width: 16px;
  transform: rotate(45deg);
  transition: all 1000ms ease;
  -webkit-animation: st__rotate 10s infinite linear;
  -moz-animation: st__rotate 5s infinite linear;
  -o-animation: st__rotate 5s infinite linear;
  animation: st__rotate 5s infinite linear;
  -webkit-animation-play-state: running;
  -moz-animation-play-state: running;
  -o-animation-play-state: running;
  animation-play-state: running;
  z-index: 1;
}

.st__team__box {
  background: #003d4d;
  padding: 0 0 37px;
  border-radius: 280px 280px 0 0;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.st__team__box:before {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0%;
  background: #f39f2f;
  transition: .5s;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  -ms-transition: .5s;
  -o-transition: .5s;
  z-index: -1;
}

.st__team__thumb img {
  width: 100%;
}

.st__team__content {
  margin-top: 32px;
}

.st__team__content h2 {
  font-size: 25px;
  font-weight: 600;
  color: #fff;
}

.st__team__content span {
  font-size: 16px;
  color: #f4f4f9;
  margin-top: 3px;
  display: inline-block;
}

.st__team__box:hover:before {
  height: 100%;
}

/* scroll to top */
.scroll-to-top {
  display: inline-block;
  width: 40px;
  height: 40px;
  background: var(--onpoint-black);
  position: fixed;
  bottom: 83px;
  right: 28px;
  z-index: 99;
  text-align: center;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  display: none;
  border-radius: 50%;
}

.scroll-to-top i {
  color: var(--onpoint-white);
  font-size: 18px;
  line-height: 40px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.scroll-to-top:hover {
  background-color: var(--onpoint-base);
}

.scroll-to-top:hover i {
  color: var(--onpoint-white);
}

.brand-one .react-multi-carousel-list {
  padding: 70px 0 0px;
}

.brand-one .react-multiple-carousel__arrow {
  bottom: 38px;
  background: rgb(36 70 111);
  border: 1px solid #f39f2f;
  display: none;
}

.brand-one .react-multiple-carousel__arrow--left {
  left: 46%;
}

.brand-one .react-multiple-carousel__arrow--right {
  right: 46%;
}

.brand-one .react-multiple-carousel__arrow::before {
  color: #f39f2f;
}

.team-one .react-multi-carousel-list {
  position: inherit;
}

.team-one .react-multiple-carousel__arrow {
  top: 150px;
  background-color: rgb(249 231 233);
}

.team-one .react-multiple-carousel__arrow--left {
  left: 84%;
}

.team-one .react-multiple-carousel__arrow--right {
  right: 137px;
}

.team-one .react-multiple-carousel__arrow::before {
  color: #f39f2f;
}

.testimonial-one__right .react-multi-carousel-list {
  position: inherit;
}

.testimonial-one__right .react-multiple-carousel__arrow {
  bottom: 20px;
  background-color: rgb(249 231 233);
}

.testimonial-one__right .react-multiple-carousel__arrow--right {
  right: 82%;
}

.testimonial-one__right .react-multiple-carousel__arrow::before {
  color: #f39f2f;
}

.faq-one__btn-box.contact-btn a {
  background-color: #f39f2f;
  color: #fff;
}

.services-two a {
  color: #7c7c7c;
}

.ft-service-icon img {
  max-width: 65%;
}

.css-1bw0nnu-MuiStep-root {
  position: relative;
  width: 198px;
  height: 60px;
}

.css-gdvilq {

  background-image: url('../../public/images/backgrounds/home-contact-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  padding: 0;
  width: 100% !important;
}

.css-gdvilqP {
  padding: 0;
}

.css-gdvilq::before {
  top: 0;
  width: 64%;
  z-index: 0;
  content: "";
  left: -22%;
  height: 100%;
  position: absolute;
  transform: skew(-45deg);
  background-color: #f4f4f4;
}

.css-vnkopk-MuiStepLabel-iconContainer {
  background-color: #fff;
  padding-right: 0 !important;
}

/* about us section */

.ft-thx-about-section {
  padding: 100px 0px 100px;
  margin-bottom: 40px;
  margin-top: 40px;
}

.pr-cor-section-title .pr-cor-title-tag {
  font-size: 20px;
  font-weight: 700;
  color: #f39f2f;
  position: relative;
}

.pr-cor-section-title .pr-cor-title-tag:after {
  width: 50px;
  right: -55px;
}

.pr-cor-section-title .pr-cor-title-tag:before,
.pr-cor-section-title .pr-cor-title-tag:after {
  top: 18px;
  content: "";
  width: 35px;
  position: absolute;
  border-top: 2px dashed #f39f2f;
}

.pr-cor-section-title h2 {
  font-size: 36px;
  font-weight: 700;
  color: #000d51;
  padding: 20px 0px 24px;
}

.pr-cor-about-feature-area {
  padding: 28px 0px;
}

.pr-cor-about-feature-list .pr-cor-about-ft-icon {
  width: 75px;
  height: 75px;
  border-radius: 5px;
  margin-right: 30px;
  background-color: #ffeced;
}

.align-items-center {
  align-items: center !important;
}

.d-flex {
  display: flex !important;
}

.justify-content-center {
  justify-content: center !important;
}

.pr-cor-about-feature-list .pr-cor-about-ft-icon i {
  font-size: 50px;
  line-height: 0.7;
  color: #f39f2f;
}

.pr-cor-about-feature-list .pr-cor-about-ft-text {
  max-width: 455px;
}

.pr-cor-about-feature-list .pr-cor-about-ft-text h3 {
  font-size: 18px;
  font-weight: 700;
  color: #f39f2f;
  padding-bottom: 10px;
}

.pr-cor-about-img-wrap {
  z-index: 1;
}

.pr-cor-about-img-wrap .pr-cor-about-img-area,
.pr-cor-about-img-wrap .pr-cor-about-img-area2 {
  overflow: hidden;
  border-radius: 5px;
}

.pr-cor-about-img-wrap .pr-cor-about-img-shape {
  top: 100px;
  right: 100px;
  z-index: -1;
  -webkit-animation: zoominoutsinglefeatured 10s infinite;
  animation: zoominoutsinglefeatured 10s infinite;
}

.position-absolute {
  position: absolute !important;
}

.pr-cor-about-img-wrap .pr-cor-about-img-area2 {
  right: 0;
  top: 205px;
}

.pr-cor-about-img-wrap .pr-cor-about-img-area,
.pr-cor-about-img-wrap .pr-cor-about-img-area2 {
  overflow: hidden;
  border-radius: 5px;
}

@keyframes zoominoutsinglefeatured {
  0% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  50% {
    -webkit-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
  }

  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

.ft-about-section-2 {
  padding: 140px 0px 0px;
}

.ft-section-title-2 {
  margin: 0 auto;
  max-width: 580px;
}

.ft-section-title-2 .sub-title {
  font-size: 20px;
  font-weight: 700;
  color: #f39f2f;
}

.ft-section-title-2 h2 {
  color: #003440;
  font-size: 36px;
  font-weight: 700;
  padding-top: 15px;
  padding-bottom: 25px;
}

.ft-section-title-2 h2 span {
  color: #f39f2f;
}

.ft-about-feature-wrapper-2 {
  margin-top: 40px;
}

.ft-about-feature-wrapper-2 .ft-btn-3 {
  margin-top: 40px;
}

.ft-about-feature-wrapper-2 .ft-about-feature-list-item {
  margin-bottom: 0;
  padding: 18px 15px 20px 15px;
}

.ft-about-feature-list-item {
  padding: 27px 20px 25px 20px;
  border-radius: 6px;
  margin-bottom: 30px;
  -webkit-box-shadow: 1.91px 10.833px 50px 0px rgba(68, 68, 68, 0.15);
  box-shadow: 1.91px 10.833px 50px 0px rgba(68, 68, 68, 0.15);
}

.ft-about-feature-wrapper-2 .ft-about-feature-list-item .ft-about-feature-icon {
  background-color: transparent;
  margin-right: 15px;
}

.ft-about-feature-list-item .ft-about-feature-icon {
  width: 55px;
  height: 55px;
  margin-right: 20px;
  border-radius: 100%;
  background-color: #ffebeb;
  -webkit-transition: 500ms all ease;
  transition: 500ms all ease;
  padding-top: 5px;
}

.ft-about-feature-list-item .ft-about-feature-icon i {
  font-size: 30px;
  line-height: 0.75;
  color: #f39f2f;
  -webkit-transition: 500ms all ease;
  transition: 500ms all ease;
}

.ft-about-feature-list-item .ft-about-feature-text {
  max-width: 90%;
}

.ft-about-feature-list-item .ft-about-feature-text h3 {
  color: #00044b;
  font-size: 20px;
  font-weight: 700;
  padding-bottom: 5px;
}

.ft-about-img-2-wrapper {
  padding-left: 40px;
  z-index: 1;
}

.headline p {
  text-align: justify;
}

.btn-number {
  width: 75px;
  height: 55px;
  background-color: #f39f2f;
  border-color: #f39f2f;
  color: #ffffff;
}

.btn-number:hover {
  background-color: #f39f2f;
  border-color: #f39f2f;
}

.form-select-contact {
  display: block !important;
  height: 60px;
  /* border-radius: 10px; */
  font-size: 14px;
  border: 1px solid #e3e4e9;
}

.form-select-home {
  display: block !important;
  height: 55px;
  border-radius: 5px;
  /* font-size: 13px;  */
  border: 1px solid #e3e4e9;
}

.textarea-contact {
  border: 1px solid #e3e4e9;
  height: 120px;
}

.nice-select {
  height: 0px;
  padding: 0px;
  border: none;
}

.current {
  /* display: none; */
}

textarea {
  resize: none;
}

.vertical-text {
  color: #000000;
  font-weight: 600;
}


/* service section */
.ft1-services-section {
  position: relative;
  padding: 100px 0px 245px;
}

.ft1-services-section .owl-theme .service-block {
  margin: 0px 15px;
}

.ft1-service-block {
  position: relative;
  margin-bottom: 30px;
}

.ft1-service-block .inner-box {
  position: relative;
  /* padding: 40px 25px; */
  border-radius: 6px;
  overflow: hidden;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  height: 30rem;
}

.ft1-service-block .inner-box .side-icon {
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 1;
  margin: 50px;
}

.ft1-service-block .inner-box .color-layer {
  position: absolute;
  right: -145px;
  top: -100px;
  width: 380px;
  height: 380px;
  border-radius: 50%;
  background-color: #f39f2f;
}

.ft1-service-block .inner-box .color-layer:before {
  position: absolute;
  content: "";
  left: -15px;
  top: -15px;
  right: -15px;
  bottom: -15px;
  border-radius: 50%;
  border: 2px dashed #000d51;
}

.ft1-service-block .inner-box h4 {
  text-transform: uppercase;
  padding: 25px 15px 0 25px;
}

.ft1-service-block .inner-box .text {
  position: relative;
  color: #666666;
  font-size: 16px;
  margin-top: 22px;
  margin-bottom: 28px;
  margin-right: 2.9rem;
  text-align: justify;
  padding-left: 25px;
}

.auto-container {
  position: static;
  max-width: 1200px;
  padding: 0px 15px;
  margin: 0 auto;
}

.vertical-heading {
  font-weight: 700;
  font-size: 20px;
  color: #000d51;
}

.content img {
  float: right;
  shape-outside: polygon(97% 0, 46% 96%, 0 50%, 0 19%, 75% 0);
  width: 272px;
  ;
}

/* service section end*/



/*---------------------------------------------------- */
/*FAQ area*/
/*----------------------------------------------------*/

.faqs-padding {
  padding: 100px 0 245px;
}

.ft-faq-content .accordion-button:not(.collapsed),
.ft-faq-content .accordion-item:first-of-type .accordion-button {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ft-faq-content .accordion-item:first-of-type {
  border-radius: 0;
}

.ft-faq-content .accordion-item {
  margin-bottom: 25px;
}

.ft-faq-content .accordion-item .accordion-button {
  color: #003440;
  font-size: 16px;
  font-weight: 600;
  padding: 18px 20px 17px;
}

.ft-faq-content .accordion-item .accordion-button:hover,
.ft-faq-content .accordion-item .accordion-button:focus {
  outline: none;
}

.ft-faq-content .accordion-item .accordion-button:not(.collapsed) {
  color: #f39f2f;
  background-color: transparent;
}

.ft-faq-content .accordion-item:first-of-type .accordion-button {
  border-radius: 0;
}

.ft-faq-content .accordion-button::after {
  right: 15px;
  color: #003440;
  content: "\f067";
  font-weight: 900;
  font-size: 20px;
  position: absolute;
  background-image: none;
  font-family: "Font Awesome 5 Free";
}

.ft-faq-content .accordion-button:not(.collapsed)::after {
  content: "\f068";
  color: #f39f2f;
}

.ft-faq-content .accordion-body {
  padding-top: 5px;
}

.ft-faq-content .accordion-button:focus {
  border: none;
}

@keyframes icon-bounce {

  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }

  60% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}

.ft-faq-page-top-content {
  margin-bottom: 50px;
}

.ft-faq-page-top-content .ft-section-title-2 {
  margin: 0;
  /* max-width: 435px; */
}

.ft-faq-page-top-content .ft-section-title-2 h2 {
  padding: 0;
}

.ft-faq-contact-form-wrapper {
  margin-top: 50px;
}

.ft-faq-contact-form-area {
  padding: 30px;
  border-radius: 10px;
  -webkit-box-shadow: 1.736px 9.848px 60px 0px rgba(0, 52, 64, 0.15);
  box-shadow: 1.736px 9.848px 60px 0px rgba(0, 52, 64, 0.15);
}

.ft-faq-contact-form-area .ft-section-title-2 h2 {
  padding-top: 0;
  font-size: 24px;
}

.ft-faq-contact-form-area input,
.ft-faq-contact-form-area textarea {
  width: 100%;
  height: 55px;
  border-radius: 5px;
  padding-left: 20px;
  margin-bottom: 20px;
  border: 1px solid #cbcbcb;
}

.ft-faq-contact-form-area input::-webkit-input-placeholder,
.ft-faq-contact-form-area textarea::-webkit-input-placeholder {
  color: #c3c3c3;
}

.ft-faq-contact-form-area input::-moz-placeholder,
.ft-faq-contact-form-area textarea::-moz-placeholder {
  color: #c3c3c3;
}

.ft-faq-contact-form-area input:-ms-input-placeholder,
.ft-faq-contact-form-area textarea:-ms-input-placeholder {
  color: #c3c3c3;
}

.ft-faq-contact-form-area input::-ms-input-placeholder,
.ft-faq-contact-form-area textarea::-ms-input-placeholder {
  color: #c3c3c3;
}

.ft-faq-contact-form-area input::placeholder,
.ft-faq-contact-form-area textarea::placeholder {
  color: #c3c3c3;
}

.ft-faq-contact-form-area textarea {
  height: 130px;
  padding-top: 10px;
}

.ft-faq-form-img {
  overflow: hidden;
  border-radius: 10px;
  -webkit-box-shadow: 1.736px 9.848px 60px 0px rgba(0, 52, 64, 0.15);
  box-shadow: 1.736px 9.848px 60px 0px rgba(0, 52, 64, 0.15);
}

.ft-submit-btn {
  color: #fff;
  height: 55px;
  width: 200px;
  border: none;
  margin-top: 10px;
  font-weight: 600;
  border-radius: 30px;
  font-family: "Poppins";
  -webkit-transition: 500ms all ease;
  transition: 500ms all ease;
  background-color: #f39f2f;
}

.ft-submit-btn:hover {
  color: #fff;
  background-color: #00044b;
}

.collapse {
  visibility: visible !important;
}

.main-menu__list li.active a{
  color: #f39f2f !important;
}

.team-details {
  display: flex;
  justify-content: center;
}

.policy-privacy {
  padding: 100px 0 245px;
}

.icon i {
  margin-right: 15px;
}

/* faq end */


/*--------------------------------------------------------------
# Blog Page  
--------------------------------------------------------------*/
.blog-page {
  position: relative;
  display: block;
  padding: 120px 0 245px;
  z-index: 1;
}

.blog-page__single {
  position: relative;
  display: block;
  margin-bottom: 60px;
}

.blog-page__img {
  position: relative;
  display: block;
}

.blog-page__img img {
  width: 100%;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

.blog-page__date {
  position: absolute;
  right: 55px;
  bottom: -19px;
  z-index: 2;
}

.blog-page__date p {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 107px;
  height: 38px;
  font-size: 15px;
  font-weight: 700;
  color: var(--onpoint-white);
  font-family: var(--onpoint-font-two);
  background-color: var(--onpoint-base);
  border-radius: 5px;
}

.blog-page__content {
  position: relative;
  display: block;
  border: 1px solid var(--onpoint-bdr-color);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 29px 40px 40px;
}

.blog-page__meta {
  position: relative;
  display: flex;
  align-items: center;
  gap: 17px;
}

.blog-page__meta li {
  position: relative;
  display: block;
}

.blog-page__meta li>a {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: var(--onpoint-gray);
  gap: 10px;
}

.blog-page__meta li>a:hover {
  color: var(--onpoint-base);
}

.blog-page__meta li>a>span {
  color: var(--onpoint-black);
}

.blog-page__meta li>a:hover span {
  color: var(--onpoint-base);
}

.blog-page__title {
  font-size: 48px;
  font-weight: 700;
  line-height: 58px;
  margin-top: 16px;
  margin-bottom: 15px;
}

.blog-page__title a {
  color: var(--onpoint-black);
}

.blog-page__title a:hover {
  color: var(--onpoint-base);
}

.blog-page__btn-box {
  position: relative;
  display: block;
  margin-top: 41px;
}

.blog-page__btn {
  border: 1px solid transparent;
  color: var(--onpoint-black);
  background-color: var(--onpoint-extra);
  gap: 10px;
}

.blog-page__btn>span {
  background-color: #f39f2f;
}

.blog-page__btn:hover>span {
  color: var(--onpoint-black);
}

.blog-page__social {
  position: absolute;
  right: 40px;
  bottom: 40px;
  display: flex;
  align-items: center;
}

.blog-page__social a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  font-size: 16px;
  color: var(--onpoint-black);
  border: 1px solid var(--onpoint-bdr-color);
  border-radius: 50%;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.blog-page__social a:hover {
  border: 1px solid #f39f2f;
  background-color: #f39f2f;
  color: var(--onpoint-black);
}

.blog-page__social a+a {
  margin-left: 10px;
}

.blog-page__pagination {
  position: relative;
  display: block;
  text-align: center;
}

.blog-page__pagination .pg-pagination li {
  display: inline-block;
  margin-right: 10px;
}

.blog-page__pagination .pg-pagination li a {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  display: inline-block;
  color: var(--onpoint-black);
  background-color: var(--onpoint-extra);
  font-weight: 400;
  font-size: 18px;
  border-radius: 50%;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.blog-page__pagination .pg-pagination li a:hover {
  background-color: var(--onpoint-base);
  color: var(--onpoint-white);
}

.blog-page__pagination .pg-pagination li a>i {
  font-size: 12px;
  color: var(--onpoint-base);
}

.blog-page__pagination .pg-pagination li a:hover i {
  color: var(--onpoint-white);
}

/* blog end */

/* news-details */

.ft-blog-feed-details-section {
  padding: 100px 0 50px;
}

.blog-details-img-text-wrapper .ft-blog-details-section {
  padding: 100px 0px;
}

.blog-details-img-text-wrapper .ft-blog-details-item {
  padding: 20px 30px;
  background-color: #f4f4f4;
}

.blog-details-img-text-wrapper .ftd-blog-meta-2 {
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e4ebf2;
}

.blog-details-img-text-wrapper .ftd-blog-meta-2:after {
  bottom: 0;
  left: 0;
  height: 3px;
  width: 40px;
  content: "";
  position: absolute;
  background-color: #f39f2f;
}

.blog-details-img-text-wrapper .ftd-blog-meta-2 a {
  color: #707070;
  margin-right: 25px;
  position: relative;
}

.blog-details-img-text-wrapper .ftd-blog-meta-2 a i {
  font-size: 18px;
  margin-right: 5px;
  color: #f39f2f;
}

.blog-details-img-text-wrapper .blog-details-text article {
  margin-bottom: 25px;
}

.blog-details-img-text-wrapper .blog-details-text h3 {
  color: #000000;
  font-size: 26px;
  font-weight: 700;
  padding-bottom: 20px;
}

.blog-details-img-text-wrapper .blog-details-text .bd-video-play {
  left: 0;
  right: 0;
  top: 50%;
  position: absolute;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.blog-details-img-text-wrapper .blog-details-text .bd-video-play a {
  width: 85px;
  height: 85px;
  color: #f39f2f;
  line-height: 85px;
  border-radius: 100%;
  display: inline-block;
  background-color: #fff;
}

.blog-details-img-text-wrapper .blog-details-text blockquote {
  color: #191919;
  padding: 30px;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 30px;
  font-family: "Poppins";
  background-color: #fff;
  border-left: 5px solid #f39f2f;
}

.blog-details-img-text-wrapper .blog-details-text blockquote span {
  display: block;
  font-size: 15px;
  margin-top: 10px;
  font-weight: 700;
  font-style: italic;
  color: #f39f2f;
}

.blog-details-img-text-wrapper .ft-blog-tag-share {
  margin: 40px 0px;
}

.blog-details-img-text-wrapper .ft-blog-tag-share .ft-blog-tag span {
  color: #222222;
  font-size: 15px;
  font-weight: 700;
  margin-right: 10px;
}

.blog-details-img-text-wrapper .ft-blog-tag-share .ft-blog-tag a {
  color: #222222;
  font-size: 15px;
  font-weight: 500;
  margin-right: 3px;
  padding: 10px 20px;
  display: inline-block;
  background-color: #ffff;
  -webkit-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}

.blog-details-img-text-wrapper .ft-blog-tag-share .ft-blog-tag a:hover {
  color: #fff;
  background-color: #f39f2f;
}

.blog-details-img-text-wrapper .ft-blog-tag-share .ft-blog-share a {
  width: 30px;
  height: 30px;
  color: #fff;
  text-align: center;
  margin-left: 5px;
  line-height: 30px;
  overflow: hidden;
  border-radius: 30px;
  display: inline-block;
  -webkit-transition: 500ms all ease;
  transition: 500ms all ease;
}

.blog-details-img-text-wrapper .ft-blog-tag-share .ft-blog-share a span {
  font-size: 14px;
}

.blog-details-img-text-wrapper .ft-blog-tag-share .ft-blog-share a:hover {
  width: 100px;
  border-radius: 30px;
}

.blog-details-img-text-wrapper .ft-blog-tag-share .ft-blog-share a:hover i {
  margin-right: 5px;
}

.blog-details-img-text-wrapper .ft-blog-tag-share .ft-blog-share .fb-social {
  background-color: #3b5998;
}

.blog-details-img-text-wrapper .ft-blog-tag-share .ft-blog-share .tw-social {
  background-color: #55acee;
}

.blog-details-img-text-wrapper .ft-blog-tag-share .ft-blog-share .ln-social {
  background-color: #dd4b39;
}

.blog-details-img-text-wrapper .ft-blog-tag-share .ft-blog-share .in-social {
  background-color: #cd339a;
}

.blog-details-img-text-wrapper .ft-blog-next-prev {
  border: 1px solid #e0e0e0;
  border-left: none;
  border-right: none;
  padding: 10px 0px 30px;
  margin-bottom: 50px;
}

.blog-details-img-text-wrapper .ft-blog-next-prev .ft-blog-next-prev-btn .np-text {
  color: #191919;
  font-size: 15px;
  line-height: 50px;
  font-weight: 700;
  -webkit-transition: 0.3s all ease;
  transition: 0.3s all ease;
  position: relative;
}

.blog-details-img-text-wrapper .ft-blog-next-prev .ft-blog-next-prev-btn .np-text:after {
  left: 0;
  content: "";
  width: 0%;
  height: 1px;
  bottom: -2px;
  position: absolute;
  -webkit-transition: 500ms all ease;
  transition: 500ms all ease;
  background-color: #f39f2f;
}

.blog-details-img-text-wrapper .ft-blog-next-prev .ft-blog-next-prev-btn .np-text:hover {
  color: #f39f2f;
}

.blog-details-img-text-wrapper .ft-blog-next-prev .ft-blog-next-prev-btn .np-text:hover:after {
  width: 100%;
}

.blog-details-img-text-wrapper .ft-blog-next-prev .ft-blog-next-prev-btn .ft-blog-np-img {
  width: 60px;
  height: 52px;
  overflow: hidden;
  border-radius: 10px;
  margin-right: 20px;
  position: relative;
}

.blog-details-img-text-wrapper .ft-blog-next-prev .ft-blog-next-prev-btn .ft-blog-np-img:before {
  top: 0;
  left: 0;
  width: 100%;
  content: "";
  opacity: 0;
  height: 100%;
  position: absolute;
  background-color: #f39f2f;
  -webkit-transition: 500ms all ease;
  transition: 500ms all ease;
}

.blog-details-img-text-wrapper .ft-blog-next-prev .ft-blog-next-prev-btn .ft-blog-np-text h3 {
  color: #000000;
  font-size: 16px;
  line-height: 1.5;
  max-width: 300px;
}

.blog-details-img-text-wrapper .ft-blog-next-prev .ft-blog-next-prev-btn .ft-blog-np-text h3 a:hover {
  text-decoration: underline;
}

.blog-details-img-text-wrapper .ft-blog-next-prev .ft-blog-next-prev-btn .ft-blog-np-text h3 a {
  color: #f39f2f;
}

.blog-details-img-text-wrapper .ft-blog-next-prev .ft-blog-next-prev-btn.np-text-item .ft-blog-np-img {
  margin-right: 0;
  margin-left: 20px;
}

.blog-details-img-text-wrapper .ft-blog-next-prev .ft-blog-next-prev-btn .ft-blog-next-prev-img-text:hover .ft-blog-np-img:before {
  opacity: 0.7;
}

.blog-details-img-text-wrapper .blog-details-img-video {
  margin-bottom: 30px;
}

.blog-details-img-text-wrapper .blog-details-img-video .blog-details-img {
  overflow: hidden;
  max-width: 390px;
  border-radius: 7px;
}

.ft-video-section {
  padding-bottom: 105px;
}

@-webkit-keyframes border_animation {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }

  20% {
    -webkit-transform: scale(1.24);
    transform: scale(1.24);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(2.1);
    transform: scale(2.1);
    opacity: 0;
  }
}

@keyframes border_animation {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }

  20% {
    -webkit-transform: scale(1.24);
    transform: scale(1.24);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(2.1);
    transform: scale(2.1);
    opacity: 0;
  }
}

.ft-video-play {
  left: 0;
  top: 50%;
  right: 0;
  margin: 0 auto;
  text-align: center;
  position: absolute;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.ft-video-play a {
  width: 60px;
  height: 60px;
  margin: 0 auto;
  border-radius: 100%;
  background-color: #f39f2f;
}

.ft-video-play a i {
  color: #fff;
}

.ft-video-play a .video_btn_border {
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  border-radius: 60px;
  position: absolute;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  border: 10px solid #f39f2f;
  -webkit-animation: border_animation 3.9s linear 0s infinite;
  animation: border_animation 3.9s linear 0s infinite;
}

.ft-video-play a .video_btn_border.border_wrap-2 {
  -webkit-animation-delay: 1.3s;
  animation-delay: 1.3s;
}

.ft-side-bar-widget {
  padding: 25px 20px;
  margin-bottom: 30px;
  background-color: #f4f4f4;
}

.ft-side-bar-widget.search-widget-area {
  padding: 0;
}

.ft-side-bar-widget .widget-title {
  color: #002a34;
  font-size: 22px;
  font-weight: 700;
  padding-bottom: 45px;
}

.ft-side-bar-widget .widget-title:after {
  left: 0;
  top: 35px;
  height: 3px;
  content: "";
  width: 40px;
  position: absolute;
  background-color: #f39f2f;
}

.ft-side-bar-widget .search-widget input {
  width: 100%;
  height: 55px;
  border: none;
  padding-left: 20px;
  background-color: #f4f4f4;
}

.ft-side-bar-widget .search-widget button {
  top: 15px;
  padding: 0;
  right: 15px;
  border: none;
  position: absolute;
  background-color: transparent;
}

.ft-side-bar-widget .category-widget li {
  margin-bottom: 15px;
}

.ft-side-bar-widget .category-widget li:last-child {
  margin-bottom: 0;
}

.ft-side-bar-widget .category-widget li a {
  color: #002a34;
  font-weight: 500;
  padding-left: 15px;
  position: relative;
}

.ft-side-bar-widget .category-widget li a:before {
  left: 0;
  top: -1px;
  font-size: 14px;
  content: "";
  font-weight: 900;
  position: absolute;
  font-family: "Font Awesome 5 Free";
}

.ft-side-bar-widget .category-widget li a span {
  color: #fff;
  width: 28px;
  float: right;
  height: 28px;
  line-height: 28px;
  text-align: center;
  background-color: #002a34;
  -webkit-transition: 400ms all ease;
  transition: 400ms all ease;
}

.ft-side-bar-widget .category-widget li:hover a {
  margin-left: 15px;
  color: #f39f2f;
}

.ft-side-bar-widget .category-widget li:hover span {
  background-color: #f39f2f;
}

.ft-side-bar-widget .recent-news-widget .recent-blog-img-text {
  margin-bottom: 60px;
}

.ft-side-bar-widget .recent-news-widget .recent-blog-img-text .recent-blog-img {
  width: 58px;
  height: 68px;
  overflow: hidden;
  margin-right: 10px;
}

.ft-side-bar-widget .recent-news-widget .recent-blog-img-text .recent-blog-text h3 a {
  font-size: 15px;
  color: #f39f2f;
  font-weight: 500;
  padding-bottom: 5px;
}

.ft-side-bar-widget .recent-news-widget .recent-blog-img-text .recent-blog-text span {
  font-size: 15px;
}

.ft-side-bar-widget .recent-news-widget .recent-blog-img-text .recent-blog-text span i {
  color: #002a34;
  margin-right: 5px;
}

.ft-side-bar-widget .recent-news-widget .recent-blog-img-text:last-child {
  margin-bottom: 0;
}

.ft-side-bar-widget .gallery-widget {
  margin: 0px -5px;
}

.ft-side-bar-widget .gallery-widget li {
  width: 75px;
  height: 65px;
  padding: 5px;
  overflow: hidden;
  border-radius: 5px;
  display: inline-block;
}

.ft-side-bar-widget .tag-widget li {
  display: inline-block;
}

.ft-side-bar-widget .tag-widget li a {
  color: #fff;
  font-size: 14px;
  padding: 5px 15px;
  margin-bottom: 5px;
  display: inline-block;
  background-color: #003440;
}

.ft-side-bar-widget .tag-widget li a:hover {
  background-color: #f39f2f;
}

.ft-blog-comment h3 {
  color: #191919;
  font-size: 24px;
  display: inline-block;
  font-weight: 700;
  position: relative;
}

.ft-blog-comment h3:after {
  left: 0;
  height: 3px;
  bottom: -8px;
  width: 40px;
  content: "";
  position: absolute;
  background-color: #f39f2f;
}

.ft-blog-comment-block-wrapper {
  padding-top: 35px;
}

.ft-blog-comment-block {
  margin-bottom: 40px;
}

.ft-blog-comment-block .ft-blog-comment-img {
  width: 90px;
  height: 90px;
  overflow: hidden;
  margin-right: 20px;
  border-radius: 100%;
}

.ft-blog-comment-block .ft-blog-comment-text {
  display: table;
  padding: 20px 25px;
  background-color: #fff;
  -webkit-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}

.ft-blog-comment-block .ft-blog-comment-text h4 {
  color: #000;
  font-size: 20px;
  font-weight: 700;
  padding-bottom: 5px;
}

.ft-blog-comment-block .ft-blog-comment-text .ftd-reply-btn {
  top: 20px;
  right: 20px;
  height: 35px;
  width: 85px;
  color: #fff;
  font-size: 14px;
  line-height: 35px;
  position: absolute;
  border-radius: 20px;
  background-color: #000;
  -webkit-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}

.ft-blog-comment-block .ft-blog-comment-text .ftd-reply-btn i {
  font-size: 13px;
}

.ft-blog-comment-block .ft-blog-comment-text .ftd-reply-btn:hover {
  color: #fff;
  background-color: #f39f2f;
}

.ft-blog-comment-block .ft-blog-comment-text span {
  font-size: 13px;
  font-weight: 700;
  color: #f39f2f;
  text-transform: uppercase;
}

.ft-blog-comment-block .ft-blog-comment-text p {
  font-size: 15px;
  padding-top: 10px;
}

.ft-blog-comment-block .ft-blog-comment-text:hover {
  -webkit-box-shadow: 0px 8px 38px 0px rgba(125, 125, 125, 0.16);
  box-shadow: 0px 8px 38px 0px rgba(125, 125, 125, 0.16);
}

.ft-blog-comment-block .ft-blog-comment-text:hover span {
  text-decoration: underline;
}

.ftd-blog-comment-form {
  margin-top: 40px;
  padding: 35px 30px;
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 35px 0px rgba(12, 12, 12, 0.05);
  box-shadow: 0px 0px 35px 0px rgba(12, 12, 12, 0.05);
}

.ftd-blog-comment-form .ftd-comment-form-input label {
  color: #000;
  font-size: 14px;
  margin-bottom: 10px;
}

.ftd-blog-comment-form .ftd-comment-form-input input,
.ftd-blog-comment-form .ftd-comment-form-input textarea {
  height: 50px;
  width: 33.33%;
  margin: 0px 5px;
  padding-left: 20px;
  border: 1px solid #e3e7f2;
}

.ftd-blog-comment-form .ftd-comment-form-input span {
  display: block;
  margin-top: 15px;
}

.ftd-blog-comment-form .ftd-comment-form-input span input {
  height: inherit;
  width: inherit;
}

.ftd-blog-comment-form .ftd-comment-form-input textarea {
  width: 100%;
  height: 150px;
  padding-top: 15px;
}

.ftd-blog-comment-form .ftd-comment-form-input button {
  color: #fff;
  width: 150px;
  height: 45px;
  border: none;
  font-size: 14px;
  margin-top: 15px;
  font-weight: 700;
  border-radius: 30px;
  -webkit-transition: 500ms all ease;
  transition: 500ms all ease;
  text-transform: uppercase;
  background-color: #f39f2f;
}

.ftd-blog-comment-form .ftd-comment-form-input button:hover {
  background-color: #00044b;
}

.ft-blog-comment-text h4 a {
  color: #f39f2f;
}

/* news-details */

/* CSR start */
.blog-grid {
  position: relative;
  display: block;
  padding: 120px 0 120px;
}

.blog-grid__single {
  position: relative;
  display: block;
  margin-bottom: 89px;
}

.blog-grid__img-box {
  position: relative;
  display: block;
}

.blog-grid__img {
  position: relative;
  display: block;
  overflow: hidden;
  background-color: #000000;
}

.blog-grid__img img {
  width: 100%;
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.blog-grid__content-box {
  position: relative;
  display: block;
  background-color: #ffffff;
  padding-top: 31px;
  padding-left: 35px;
  margin-left: 40px;
  margin-top: -55px;
}

.blog-grid__meta-box {
  position: relative;
  display: flex;
  align-items: center;
}

.blog-grid__date {
  position: relative;
  display: block;
}

.blog-grid__meta {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 46px;
  top: 2px;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.blog-grid__meta li {
  position: relative;
  display: block;
}

.blog-grid__meta li a {
  color: #666666;
}

.blog-grid__meta li+li {
  margin-left: 35px;
}

.blog-grid__meta li {
  position: relative;
  display: block;
}

.blog-grid__meta li:before {
  content: "";
  position: absolute;
  top: 10px;
  bottom: 8px;
  left: -17px;
  background-color: #666666;
  width: 1px;
  transform: rotate(15deg);
}

.blog-grid__content {
  position: relative;
  display: block;
  margin-top: 45px;
}

.blog-grid__title {
  font-size: 34px;
  font-weight: 900;
  line-height: 41px;
  text-transform: capitalize;
}

.blog-grid__title a {
  color: var(--assimox-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.blog-grid__text {
  position: relative;
  display: block;
  padding-top: 18px;
  padding-bottom: 15px;
}

.blog-grid__single:hover .blog-grid__img img {
  opacity: 0.30;
  transform: scale(1.05);
  transition: all 0.5s cubic-bezier(0.62, 0.21, 0.45, 1.52);
}

.blog-grid__title a:hover {
  color: var(--onpoint-base);
}

.pb-50 {
  padding-bottom: 245px;
}

/* CSR end */

/* 1wn-banner images */
.csr-banner {
  background-image: url(../../../1wn-website/public/images/csr/csr-banner.webp) !important;

}

.about-banner {
  background-image: url(../../../1wn-website/public/images/header-banner/about-banner.webp);
}

.vertical-banner {
  background-image: url(../../../1wn-website/public/images/header-banner/vertical-banner.webp);
}

.news-media-banner {
  background-image: url(../../../1wn-website/public/images/header-banner/news-media-banner.webp)
}

.membersship-banner {
  background-image: url(../../../1wn-website/public/images/header-banner/membership-banner.webp);
}

.blog-banner {
  background-image: url(../../../1wn-website/public/images/header-banner/blog-banner.webp);
}

.blog-details-banner {
  background-image: url(../../../1wn-website/public/images/header-banner/blog-details-banner.webp);
}

.contact-banner {
  background-image: url(../../../1wn-website/public/images/header-banner/contact-banner.png);
}

.post-event-gallery-banner {
  background-image: url(../../../1wn-website/public/images/header-banner/post-event-banner.webp);
}

.event-conferences-banner {
  background-image: url(../../../1wn-website/public/images/header-banner/event-conferences-banner.webp);
}

.news-media-deatils-banner {
  background-image: url(../../../1wn-website/public/images/header-banner/news-media-details-banner.webp);
}

.cookie-banner {
  background-image: url(../../../1wn-website/public/images/header-banner/cookie-policy-banner.webp);
}

.privacy-policy-banner {
  background-image: url(../../../1wn-website/public/images/header-banner/privacy-policy-banner.webp);
}

.terms-condition-banner {
  background-image: url(../../../1wn-website/public/images/header-banner/terms-condition-banner.webp);
}

.faqs-banner {
  background-image: url(../../../1wn-website/public/images/header-banner/faqs-banner.webp);
}

.conference-banner {
  background-image: url(../../../1wn-website/public/images/header-banner/conference-banner.webp);
}

.csr-details-banner {
  background-image: url(../../../1wn-website/public/images/header-banner/csr-details-banner.webp);
}

.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
  height: 36px !important;
}

.next-btn {
  display: flex;
  justify-content: end !important;
}
.event-reg-form {
  padding: 50px 0 !important;
}

.backBtn {
  margin-right: 100px !important;
}

.radiobutton {
  border: 1px solid #cecece;
  /* padding: 10px 10px 10px 0px; */
}

.MuiFormControlLabel-labelPlacementEnd {
  margin: 0px 12px !important;
  padding: 10px 0;
}
.thm-btn.member-ship-btn{
  padding: 8px 25px;
  font-size: 14px;
}
.thm-btn.member-ship-btn a{
  color: #fff;
}
.membership-container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 55%;
}
.membership-wrapper{
  background-color: #fffbf5;
  /* background-image: linear-gradient(to right, #fffbf5, #f0f2f6); */
  padding-top: 10px !important;
}
.social-menu ul{
  padding: 0;
  margin-top: 15px;
  display: flex;
}

.social-menu ul li{
  list-style: none;
  margin: 0 15px;
}

.social-menu ul li .fab{
  font-size: 27px;
  line-height: 50px;
  transition: .3s;
  color: #f29f2d;
}

.social-menu ul li .fab:hover{
  color: #fff;
}

.social-menu ul li a{
  position: relative;
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #fff;
  text-align: center;
  transition: .6s;
  box-shadow: 0 5px 4px rgba(0,0,0,.5);
}

.social-menu ul li a:hover{
  transform: translate(0, -10%);
}

.social-menu ul li:nth-child(1) a:hover{
  background-color: rgba(0, 0, 0, 0.829);
}
.social-menu ul li:nth-child(2) a:hover{
  background-color: #E4405F;
}
.social-menu ul li:nth-child(3) a:hover{
  background-color: #0077b5;
}
.social-menu ul li:nth-child(4) a:hover{
  background-color: #000;
}
.ft-team-text--{
  position: relative;
  background-color: #244770;
  color: #fff;
  padding: 20px;
}
.ft-team-text--::before{
  content: '';
  height: 100%;
  width: 252px;
  position: absolute;
  top: 0;
  z-index: 0;
  left: -248px;
  background-color: #244770;
}
#membership .mui-tables{
  position: absolute;
    right: 0;
    left: 50%;
    overflow: auto;
    width: 40%;
    top: 120px;
    background: azure;
}
.mui-tables-body{
  background-color: #fff;
  height: 260px;
}
.recaptcha-obj{
  position: absolute;
  right: 30%;
  bottom: 92px;
}
#membership .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label{
  color: #000;
  position: relative;
}
/*--------------------------------------------------------------
  # End
  --------------------------------------------------------------*/