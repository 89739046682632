@media only screen and (min-width: 1200px) and (max-width: 1319px) {
    .why-choose-one__left {
        margin-right: 50px;
    }

    .about-one__shape-1 {
        display: none;
    }

    .about-one__right {
        margin-left: 0;
    }

    .team-one__title {
        font-size: 22px;
    }

    .testimonial-one__dot-style {
        right: 495px;
    }

}








/* Medium screen  */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .why-choose-one__left {
        margin-right: 0;
    }

    .about-one__left {
        max-width: 700px;
        margin: 0 auto 0;
    }

    .about-one__right {
        max-width: 600px;
        margin: 50px auto 0;
    }

    .counter-one__left {
        max-width: 520px;
        margin-bottom: 50px;
    }

    .why-are-we__left {
        margin-right: 0;
        margin-bottom: 60px;
        max-width: 600px;
    }

    .why-are-we__right {
        margin-left: 0;
    }

    .why-are-we__year {
        position: absolute;
        left: 0;
        top: 0;
    }

    .testimonial-one__img-one {
        display: none;
    }

    .testimonial-one__inner .section-title {
        text-align: left !important;
        margin-bottom: 60px;
    }

    .testimonial-one__inner {
        padding: 0 0 120px;
    }

    .testimonial-one__dot-style {
        bottom: 155px;
    }

    .blog-one__single {
        padding: 30px 20px 29px;
    }

    .blog-one__title {
        font-size: 22px;
    }

    .footer-widget__about {
        margin-right: 0;
    }

    .footer-widget__company {
        margin-left: 0;
    }

    .footer-widget__services {
        margin-left: 0;
        margin-top: 42px;
    }

    .footer-widget__newsletter {
        margin-top: 42px;
    }

    .services-two__single {
        padding: 59px 20px 40px;
    }


    .process-one__single {
        padding: 37px 15px 27px;
    }

    .process-one__count {
        position: relative;
        right: auto;
        top: 0;
        margin-bottom: 10px;
    }

    .dreamit-section-title {
        text-align: center;
    }

    .dreamit-section-title h4 {
        margin-left: 0;
    }

    .team-one .react-multiple-carousel__arrow {
        right: 215px;
    }

    .team-one .react-multiple-carousel__arrow {
        top: 239px;
    }

    .scroll-to-top {
        bottom: 60px;
    }

    .section-title__tagline-box {
        margin-left: 70px;
    }

}








/* Tablet Layout: 768px. */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .why-choose-one__left {
        margin-bottom: 60px;
    }

    .about-one__left {
        max-width: 600px;
        margin: 0 auto 0;
    }

    .about-one__right {
        max-width: 600px;
        margin: 50px auto 0;
    }

    .counter-one__left {
        max-width: 520px;
        margin-bottom: 50px;
    }

    .team-one__top .section-title__title {
        font-size: 44px;
    }

    .why-are-we__left {
        margin-right: 0;
        margin-bottom: 60px;
        max-width: 600px;
    }

    .why-are-we__right {
        margin-left: 0;
    }

    .why-are-we__year {
        position: absolute;
        left: 0;
        top: 0;
    }

    .faq-one__left {
        margin-bottom: 50px;
    }

    .testimonial-one__img-one {
        display: none;
    }

    .testimonial-one__inner .section-title {
        text-align: left !important;
        margin-bottom: 60px;
    }

    .testimonial-one__inner {
        padding: 0 0 120px;
    }

    .testimonial-one__dot-style {
        bottom: 155px;
        right: 0;
        left: 0;
        text-align: center;
    }

    .cta-one__icon {
        left: 90%;
    }

    .footer-widget__about {
        margin-right: 0;
    }

    .footer-widget__company {
        margin-left: 0;
    }

    .footer-widget__services {
        margin-left: 0;
        margin-top: 42px;
    }

    .footer-widget__newsletter {
        margin-top: 42px;
    }

    .site-footer__bottom-inner {
        padding: 31px 10px 31px;
        flex-direction: column;
    }

    .site-footer__bottom-text {
        margin-bottom: 10px;
    }

    .services-two {
        padding: 120px 0 50px;
    }

    .process-one__shape-1 {
        display: none;
    }

    .testimonial-one__text {
        font-size: 20px;
        line-height: 30px;
    }

    .team-details {
        display: flex;
        justify-content: center;
    }

    .ft-team-details-content {
        padding: 0px 10px;
    }

    .design-shape {
        display: none;
    }

    .dreamit-section-title {
        text-align: center;
    }

    .dreamit-section-title h4 {
        margin-left: 0;
    }

    .process-one__single {
        height: unset;
    }

    .team-one .react-multiple-carousel__arrow--right {
        right: 200px;
    }

    .team-one .react-multiple-carousel__arrow {
        top: 248px;
    }

    .ft-about-section-2 {
        padding: 60px 0px 0px;
    }

    .about-two {
        padding: 0;
    }

    .pr-cor-about-img-shape {
        display: none;
    }

    .pr-cor-about-img-wrap .pr-cor-about-img-area2 {
        right: 0;
        top: 0;
    }

    .ft-thx-about-section {
        padding: 30px 0 0;
        margin-bottom: 0;
    }

    .testimonial-one {
        padding: 80px 0 0;
    }

    .scroll-to-top {
        bottom: 57px;
    }

    .st__team__box {
        margin-bottom: 30px;
    }

}


/* Mobile Layout: 320px. */
@media only screen and (max-width: 767px) {
    .section-title__title {
        font-size: 25px;
        line-height: 35px;
    }

    .section-title__title br {
        display: none;
    }

    .why-choose-one__left {
        margin-right: 0;
        margin-bottom: 60px;
    }

    .about-one__left {
        margin-top: 0;
    }

    .about-one__shape-1 {
        display: none;
    }

    .about-one__list li {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .about-one__right {
        margin-left: 0;
        margin-top: 50px;
    }

    .about-one__img-box {
        flex-direction: column;
        align-items: baseline;
    }

    .about-one__trusted-box {
        left: 10px;
        gap: 10px;
        padding: 28px 10px 24px;
    }

    .counter-one__left {
        margin-bottom: 50px;
    }

    .counter-one__right {
        padding: 120px 15px 120px;
        padding-right: 15px;
    }

    .counter-one__count-list {
        flex-direction: column;
        align-items: baseline;
    }

    .team-one__top {
        flex-direction: column;
        align-items: baseline;
    }

    .team-one__top .section-title {
        margin-bottom: 20px;
    }

    .team-one__single {
        padding-right: 15px;
    }

    .why-are-we__left {
        margin-right: 0;
        margin-bottom: 60px;
    }

    .why-are-we__right {
        margin-left: 0;
    }

    .why-are-we__icon {
        display: none;
    }

    .why-are-we__list {
        flex-direction: column;
        align-items: baseline;
    }

    .why-are-we__list li+li {
        margin-left: 0;
    }

    .why-are-we__year {
        position: relative;
        left: 0;
        top: 0;
        padding: 45px 20px 56px;
        margin-top: 30px;
    }

    .faq-one__left {
        margin-bottom: 50px;
    }

    .faq-one__right {
        padding: 58px 20px 60px;
    }

    .testimonial-one__img-one {
        display: none;
    }

    .testimonial-one__inner .section-title {
        text-align: left !important;
        margin-bottom: 60px;
    }

    .testimonial-one__inner {
        padding: 0 0 120px;
    }

    .testimonial-one__single {
        padding: 40px 15px 119px;
    }

    .testimonial-one__text {
        font-size: 20px;
        line-height: 30px;
    }

    .testimonial-one__dot-style {
        bottom: 155px;
        right: 0;
        left: 0;
        text-align: center;
    }

    .blog-one__single {
        padding: 30px 20px 29px;
    }

    .blog-one__title {
        font-size: 22px;
    }

    .cta-one__icon {
        top: 0;
        left: 0;
        transform: translateX(0%) translateY(0%);
        margin-bottom: 15px;
        position: relative;
    }

    .cta-one__inner {
        padding: 52px 20px 50px;
    }

    .cta-one__title {
        font-size: 35px;
        line-height: 45px;
    }

    .footer-widget__about {
        margin-right: 0;
    }

    .footer-widget__company {
        margin-left: 0;
        margin-top: 42px;
        margin-bottom: 35px;
        text-align: center;
    }

    .footer-widget__services {
        margin-left: 0;
        margin-bottom: 35px;
    }

    .site-footer__bottom-inner {
        padding: 31px 10px 31px;
        flex-direction: column;
        text-align: center;
    }

    .site-footer__bottom-text {
        margin-bottom: 10px;
    }

    .site-footer__bottom-menu {
        flex-direction: column;
    }

    .site-footer__bottom-menu li+li {
        margin-left: 0;
        margin-top: 10px;
    }

    .services-two__single {
        padding: 59px 20px 40px;
    }

    .services-two {
        padding: 120px 0 50px;
    }

    .why-choose-two__big-text {
        font-size: 60px;
        line-height: 60px;
    }


    .counter-two__count-list li {
        flex: 0 0 100%;
        max-width: 100%;
    }


    .process-one__shape-1 {
        display: none;
    }

    .process-one__single {
        padding: 37px 15px 27px;
    }

    .process-one__count {
        position: relative;
        right: auto;
        top: 0;
        margin-bottom: 10px;
    }

    .blog-two__meta {
        gap: 15px;
    }

    .site-footer-two__top-title {
        font-size: 40px;
        line-height: 50px;
    }

    .footer-widget-two__about {
        margin-bottom: 41px;
    }

    .footer-widget-two__services {
        margin-bottom: 36px;
    }

    .footer-widget-two__text br {
        display: none;
    }


    .page-header {
        margin: 0 0px 0;
    }

    .page-header__inner h2 {
        font-size: 30px;
        line-height: 40px;
    }

    .testimonial-one__text {
        font-size: 20px;
        line-height: 30px;
    }

    .team-details {
        display: flex;
        justify-content: center;
    }

    .dreamit-section-title {
        text-align: center;
    }

    .dreamit-section-title h4 {
        margin-left: 0;
    }

    .ft-team-details-content .ft-team-details-text h3 {
        margin-top: 15px;
    }

}

@media only screen and (max-width: 575px) {
    .services-two__single {
        max-height: 280px;
    }

    .ft-team-details-content {
        padding: 0px 0px;
    }

    .process-one__single {
        height: 95%;
    }

    .counter-one__count-list li {
        max-width: 100%;
    }

    .team-one .react-multiple-carousel__arrow {
        top: 231px;
    }

    .testimonial-one__right .react-multiple-carousel__arrow--right {
        right: 76%;
    }

    .scroll-to-top {
        bottom: 55px;
        right: 9px;
    }

    .ft-team-details-content .ft-team-details-text h3 {
        margin-top: 15px;
    }

    .why-are-we__year>h3 {
        font-size: 40px;
    }

    .testimonial-one__right .react-multiple-carousel__arrow--right {
        right: 50%;
    }

    .testimonial-one__inner {
        padding: 0 0 0px;
    }

    #fixed-btn {
        right: -180px;
    }

    .why-are-we__icon-2 span {
        display: none;
    }

    .ft-about-section-2 {
        padding: 60px 0px 0px;
    }

    .pr-cor-about-img-wrap .pr-cor-about-img-shape {
        display: none;
    }

    .pr-cor-section-title h2 {
        margin-top: 200px;
        font-size: 33px;
    }

    .ft-thx-about-section {
        padding: 0;
        margin-bottom: 0;
    }

    .about-two {
        padding: 0;
    }

    .ft-about-shape2 img {
        margin-top: 15px;
    }

    .ft1-service-block .inner-box {
        height: 54rem;
    }

    .blog-page__meta li>a {
        gap: 0px;
    }

    .blog-page__title {
        font-size: 30px;
        line-height: 33px;
    }

    .blog-details__title-1 {
        font-size: 30px;
        line-height: 33px;
    }

    .blog-details__client-ratting {
        bottom: 10px;
        right: 50px;
    }

    .blog-details__client-name {
        line-height: 26px;
    }

    .contact-add-section {
        margin: 0px;
    }

    .contact-margin {
        margin-bottom: 15px;
    }

    .contact-detail-sec .position-relative {
        margin-bottom: 30px;
    }

    .blog-grid__content-box {
        padding-left: 0px;
        margin-left: 0px;
        margin-top: 0px;
    }

    .blog-grid__title {
        font-size: 29px;
        line-height: 32px;
    }

    .st__team__box {
        margin-bottom: 30px;
    }

    .css-qwse39-MuiGrid-root {
        margin: 0;
        padding: 10px;
    }

    .css-gdvilq::before {
        transform: none;
        background-color: unset;
    }

    .css-wb57ya-MuiFormControl-root-MuiTextField-root {
        padding: 10px;
    }

    .ft-blog-feed-details-section {
        padding: 100px 0 0;
    }

    .css-1exhn5p-MuiTypography-root {
        font-size: 2rem !important;
    }

    .ft-service-section-3 {
        padding: 0px 0px;
    }

    .cta-one {
        margin-top: 0px;
    }

    .blog-one {
        padding: 100px 0 0px;
    }

    .ft-contact-section:before {
        background-color: unset;
        transform: unset;
    }


}

@media only screen and (min-width: 1320px) and (max-width: 1500px) {}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {}


@media only screen and (min-width: 1320px) and (max-width: 1599px) {}

@media only screen and (min-width: 1200px) and (max-width: 1750px) {}

@media only screen and (min-width: 1400px) and (max-width: 1610px) {}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {}

@media only screen and (min-width: 1320px) and (max-width: 1350px) {}





































/*--------------------------------------------------------------
# Slider All Responsice Css
--------------------------------------------------------------*/
@media only screen and (min-width: 1200px) and (max-width: 1319px) {
    .banner-one__content {
        padding-left: 25px;
    }
}










@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .banner-one__right {
        margin-top: 50px;
        max-width: 520px;
    }

    .banner-one__img-two {
        left: auto;
        right: -324px;
    }
















}






@media only screen and (min-width: 768px) and (max-width: 991px) {
    /* .main-slider {
        margin: 0 0px;
    } */

    .banner-one__right {
        margin-top: 50px;
        max-width: 520px;
    }

    .banner-one__img-two {
        display: none;
    }














}







@media only screen and (max-width: 767px) {
    /* .main-slider {
        margin: 0 0px;
    } */

    /* .main-slider__title {
        font-size: 30px;
        line-height: 40px;
    } */

    /* .main-slider__title span:before {
        bottom: 4px;
    } */

    /* .main-slider__text br {
        display: none;
    } */

    /* .main-slider__btn-and-call-box {
        flex-direction: column;
        align-items: baseline;
    } */

    #main-slider-pagination {
        display: none;
    }

    .main-slider .container {
        padding-top: 194px;
        padding-bottom: 200px;
    }

    .banner-one__title {
        font-size: 40px;
        line-height: 50px;
    }

    .banner-one__right {
        margin-top: 50px;
    }

    .banner-one__img-two {
        display: none;
    }

    .banner-one__content {
        padding-left: 15px;
    }

    .banner-one__call-icon span {
        font-size: 50px;
    }

    .banner-one__call-number {
        margin-left: 20px;
    }

    .banner-one__call-number p {
        margin-bottom: 6px;
    }

    .banner-one__call-number h5 {
        font-size: 28px;
        line-height: 38px;
    }





}







@media only screen and (min-width: 1200px) and (max-width: 1470px) {}






/*--------------------------------------------------------------
# Main Menu All Responsive Css
--------------------------------------------------------------*/
@media only screen and (min-width: 1200px) and (max-width: 1319px) {
    .main-menu__nav-sidebar-icon {
        display: none;
    }

    .main-menu__search-box {
        margin-right: 20px;
    }

    .main-menu__search-box:before {
        display: none;
    }

    .main-menu-two__call {
        display: none;
    }






}











@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .main-menu-two .mobile-nav__toggler:hover {
        color: var(--onpoint-white);
    }
}








@media only screen and (min-width: 768px) and (max-width: 991px) {
    .main-menu-two .mobile-nav__toggler:hover {
        color: var(--onpoint-white);
    }
}














@media (max-width: 767px) {
    .main-menu__right {
        display: none;
    }

    .main-menu__left {
        gap: 0;
        justify-content: space-between;
        flex: 1;
    }


    .main-menu-two .mobile-nav__toggler:hover {
        color: var(--onpoint-white);
    }

    .main-menu-two__right {
        display: none;
    }

    .main-menu-two__left {
        gap: 0;
        justify-content: space-between;
        flex: 1;
    }

    .brand-one .react-multi-carousel-list {
        padding: 35px 0 35px;
    }






}

@media screen and (max-width: 400px) {
    .style-two .team-content-socails-inner {
        padding: 5px 13px;
        width: 88px;
        font-size: 13px;
    }

    .brand-one .swiper-slide img {
        height: 100%;
    }
}


@media only screen and (min-width: 1200px) and (max-width: 1470px) {}