.bannerStyle{
    background-image: url("../../../../public/images/shapes/banner-one-shape-1.png");
}

.serviceTwoStyle{
    background-image: url("../../../../public/images/shapes/sr-bg.jpg");
    background-position: bottom;
    background-repeat: no-repeat;
}

.ctaStyle{
    background-image: url("../../../../public/images/backgrounds/home-contact-img-2.png") ;
}

.contactUsImage{
    background-image: url("../../../../public/images/backgrounds/home-contact-bg.png");
}


.teamAreaStyle{
    visibility: visible;
    animation-delay: "0.8s";
    animation-name: "fadeInDown";
    
}

.teamAreaStyle1{
    visibility: visible;
    animation-delay: "0.8s"; 
    animation-name: "fadeInUp";
}

.teamAreaStyle2{
    visibility: visible;
    animation-delay: "0.8s"; 
    animation-name: "fadeInRight";
}